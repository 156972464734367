import Map, { MapGeolocation } from "components/Map";
import { FC, useEffect, useState } from "react";
import { Coords } from "google-map-react";
import { Box, Skeleton, styled } from "@mui/material";
import { SuspectVehicle } from "api/CloneDashboardAPI";
import SuspectVehiclesStatusGuide from "./SuspectVehiclesStatusGuide";

const Container = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.grey["200"]}`,
  overflow: "hidden",
  display: "flex",
  height: 350
}));

interface CloneMapProps {
  center: Coords;
  zoom: number;
  isLoading: boolean;
  suspectVehicles: SuspectVehicle[][];
}

const CloneMap: FC<CloneMapProps> = ({
  center,
  zoom,
  isLoading,
  suspectVehicles
}) => {
  const [geolocations, setGeolocations] = useState<MapGeolocation[]>([]);

  useEffect(() => {
    if (suspectVehicles.length > 0) {
      const configGeographic: MapGeolocation[] = [];
      suspectVehicles.forEach(vehicles => {
        vehicles.forEach(vehicle => {
          configGeographic.push({
            lat: Number(vehicle.latitude),
            lng: Number(vehicle.longitude),
            style: "circle"
          });
        });
      });
      setGeolocations(configGeographic || []);
    }
  }, [suspectVehicles]);

  return (
    <Container>
      <div
        style={{
          flexGrow: 1,
          width: "100%"
        }}
      >
        {isLoading && <Skeleton variant="rectangular" height={348} />}
        {!isLoading && (
          <Map
            center={center}
            zoom={zoom}
            customControls={<SuspectVehiclesStatusGuide />}
            geolocations={geolocations}
          />
        )}
      </div>
    </Container>
  );
};

export default CloneMap;
