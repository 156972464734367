import Cookies from "utils/Cookies";
import {
  setStorageTheme,
  useCustomization
} from "contexts/CustomizationContext";
import useAuthAPI, { Auth } from "api/AuthAPI";
import { createContext, FC, useContext, useEffect, useState } from "react";

const INITIAL_SESSION_USER = null;
const INITIAL_ATTEMPTING_SIGN_IN = false;
const SESSION_TIME_LIMIT = 86400; // in seconds

type SignInParams = {
  username: string;
  password: string;
};

type AuthContextValue = {
  sessionUser: null | Auth["user"];
  changeSessionUser: (() => void) | ((user: Auth["user"]) => void);
  isAttemptingSignIn: boolean;
  signIn: (() => void) | ((user: SignInParams) => Promise<void>);
  signOut: (() => void) | (() => Promise<void>);
  setAccessToken: (accessToken: string | undefined) => void;
  accessToken: string | undefined;
};

const AuthContext = createContext<AuthContextValue>({
  sessionUser: INITIAL_SESSION_USER,
  changeSessionUser() {
    throw new Error("changeSessionUser must be defined.");
  },
  isAttemptingSignIn: INITIAL_ATTEMPTING_SIGN_IN,
  signIn() {
    throw new Error("signIn must be defined.");
  },
  signOut() {
    throw new Error("signOut must be defined.");
  },
  accessToken: undefined,
  setAccessToken() {
    throw new Error("setAccessToken must be defined.");
  }
});

export const AuthProvider: FC = ({ children }) => {
  const { setCustomTheme } = useCustomization();
  const accessTokenCookie = Cookies.get(Cookies.ACCESS_TOKEN)?.toString();
  const [accessToken, setAccessToken] = useState<string | undefined>(
    accessTokenCookie
  );
  const { signIn: signInHook, signOut: signOutHook } = useAuthAPI();
  const sessionUserCookie = Cookies.get(Cookies.SESSION_USER) as Auth["user"];

  const [sessionUser, setSessionUser] = useState<null | Auth["user"]>(
    sessionUserCookie ?? INITIAL_SESSION_USER
  );
  const [isAttemptingSignIn, setAttemptingSignIn] = useState<boolean>(
    INITIAL_ATTEMPTING_SIGN_IN
  );

  const changeSessionUser = (user: Auth["user"]) => {
    setSessionUser(user);
    Cookies.set(Cookies.SESSION_USER, { ...user, customer_profile: undefined });
  };

  const removeItemsWithPrefix = (prefix: string) => {
    for (const key in localStorage) {
      if (key.startsWith(prefix)) {
        localStorage.removeItem(key);
      }
    }
  };

  const signIn = async (credentials: SignInParams) => {
    setAttemptingSignIn(true);
    try {
      const signInApiReturn = await signInHook(credentials);
      const {
        user,
        ["access_token"]: accessToken,
        ["refresh_token"]: refreshToken
      } = signInApiReturn;
      // API.setAccessToken(accessToken);
      setAccessToken(accessToken);
      Cookies.set(Cookies.ACCESS_TOKEN, accessToken);
      Cookies.set(Cookies.REFRESH_TOKEN, refreshToken);
      Cookies.set(
        Cookies.SESSION_START_TIME,
        Math.floor(new Date().getTime() / 1000)
      );
      changeSessionUser(user);
      if (user.customization_data) {
        setStorageTheme(user.customization_data);
        setCustomTheme(user.customization_data);
      }
      if (user.watermark_data) {
        Cookies.set(Cookies.WATERMARK, user.watermark_data);
      }
      Cookies.set(Cookies.SESSION_USER, {
        ...user,
        customer_profile: undefined
      });
      if (user.customer_profile?.permissions) {
        localStorage.setItem(
          "customer_profile",
          user.customer_profile?.permissions
        );
      } else {
        localStorage.removeItem("customer_profile");
      }
    } finally {
      setAttemptingSignIn(false);
    }
  };

  const signOut = async () => {
    const currentAccessToken = Cookies.get(Cookies.ACCESS_TOKEN)?.toString();
    if (!sessionUser || !currentAccessToken) return;
    try {
      await signOutHook({
        username: sessionUser.username,
        accessToken: currentAccessToken
      });
    } finally {
      // API.removeAccessToken();
      if (accessToken) setAccessToken(undefined);
      Cookies.remove(Cookies.ACCESS_TOKEN);
      Cookies.remove(Cookies.REFRESH_TOKEN);
      Cookies.remove(Cookies.SESSION_USER);
      Cookies.remove(Cookies.WATERMARK);
      Cookies.remove(Cookies.SESSION_START_TIME);
      localStorage.removeItem("customer_profile");
      setSessionUser(INITIAL_SESSION_USER);
      removeItemsWithPrefix("data_cache_");
    }
  };

  useEffect(() => {
    const secondsRemaining = setInterval(() => {
      const sessionStartTime = Cookies.get(Cookies.SESSION_START_TIME);
      if (sessionStartTime) {
        const secondsSinceLogin: number =
          Math.floor(new Date().getTime() / 1000) - Number(sessionStartTime);
        const sessionSecondsRemaining: number =
          SESSION_TIME_LIMIT - secondsSinceLogin;
        if (sessionSecondsRemaining <= 0) {
          clearInterval(secondsRemaining);
          (async () => {
            await signOut();
          })();
        }
      } else {
        clearInterval(secondsRemaining);
        (async () => {
          await signOut();
        })();
      }
    }, 1000);
    return () => {
      clearInterval(secondsRemaining);
    };
  });

  return (
    <AuthContext.Provider
      value={{
        sessionUser,
        changeSessionUser,
        isAttemptingSignIn,
        signIn,
        signOut,
        setAccessToken,
        accessToken
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextValue => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
