import React, { FC, useState, useEffect } from "react";
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  styled
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { SchedulerDate } from "api/MonitoringAPI";

const HighlightedTableCell = styled(TableCell)(({ theme }) => ({
  border: `1px solid ${theme.palette.common.white}`,
  padding: "0",
  backgroundColor: "#C2C9D1",
  "&.highlighted": {
    backgroundColor: theme.palette.info.light
  }
}));

const DayHeaderCell = styled(TableCell)({
  userSelect: "none",
  padding: "2px",
  ":hover": {
    backgroundColor: "#C2C9D1"
  }
});

const getHourIntervals = (
  hours: number[]
): { start_hour: string; end_hour: string }[] => {
  if (hours.length === 0) return [];
  hours.sort((a, b) => a - b);
  const intervals: { start_hour: string; end_hour: string }[] = [];
  let start = hours[0];
  let end = hours[0];
  for (let i = 1; i < hours.length; i++) {
    if (hours[i] === end + 1) {
      end = hours[i];
    } else {
      intervals.push({ start_hour: start + ":00", end_hour: end + ":59" });
      start = hours[i];
      end = hours[i];
    }
  }
  intervals.push({ start_hour: start + ":00", end_hour: end + ":59" });
  return intervals;
};

const tableToSchedule = (timeMap: {
  [key: string]: number[];
}): SchedulerDate[] => {
  const newSchedule: SchedulerDate[] = [];
  Object.keys(timeMap).forEach(day => {
    newSchedule.push({
      dow: day,
      hours_intervals: getHourIntervals(timeMap[day])
    });
  });
  return newSchedule;
};

const transformHourRanges = (
  ranges: { start_hour: string; end_hour: string }[]
): number[] => {
  const result: number[] = [];

  ranges.forEach(range => {
    const start = parseInt(range.start_hour.split(":")[0], 10);
    const end = parseInt(range.end_hour.split(":")[0], 10);

    for (let hour = start; hour <= end; hour++) {
      result.push(hour);
    }
  });

  return [...new Set(result)].sort((a, b) => a - b);
};

const scheduleToTable = (schedule: SchedulerDate[]) => {
  const newTimeMap: { [key: string]: number[] } = {};
  if (schedule && schedule.length > 0)
    schedule.forEach(day => {
      newTimeMap[day.dow] = transformHourRanges(day.hours_intervals);
    });
  return newTimeMap;
};

type SchedulerTableProps = {
  days: string[];
  schedulerDate: SchedulerDate[];
  setSchedulerDate: (schedulerDate: SchedulerDate[]) => void;
};

const SchedulerForm: FC<SchedulerTableProps> = ({
  schedulerDate,
  setSchedulerDate
}) => {
  const { t } = useTranslation();
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [timeMap, setTimeMap] = useState<{ [key: string]: number[] }>({});
  const handleMouseDown = (
    event: React.MouseEvent,
    day: string,
    hour: number
  ) => {
    event.preventDefault();
    setIsMouseDown(true);
    toggleHighlight(day, hour);
  };

  const handleMouseOver = (
    event: React.MouseEvent,
    day: string,
    hour: number
  ) => {
    event.preventDefault();
    if (isMouseDown) {
      toggleHighlight(day, hour);
    }
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
    setSchedulerDate([...tableToSchedule(timeMap)]);
  };

  const toggleHighlight = (day: string, hour: number) => {
    const newTimeMap = { ...timeMap };
    const index = newTimeMap[day].indexOf(hour);
    if (index === -1) {
      newTimeMap[day].push(hour);
    } else {
      newTimeMap[day].splice(index, 1);
    }
    setTimeMap({ ...newTimeMap });
  };

  const weekDayClick = (day: string) => {
    const newTimeMap = { ...timeMap };
    if (newTimeMap[day].length === 0) {
      newTimeMap[day] = Array.from({ length: 24 }).map((_, idx) => idx);
    } else {
      newTimeMap[day] = [];
    }
    setTimeMap({ ...newTimeMap });
    setSchedulerDate([...tableToSchedule(newTimeMap)]);
  };

  useEffect(() => {
    const defaultTimeMap = scheduleToTable(schedulerDate);
    setTimeMap(defaultTimeMap);
  }, [schedulerDate]);

  return (
    <Box>
      <Table onMouseUp={handleMouseUp} onMouseLeave={handleMouseUp}>
        <TableHead>
          <TableRow>
            <TableCell>Horas:</TableCell>
            {Array.from({ length: 24 }).map((_, idx) => (
              <TableCell key={idx} align="center" sx={{ padding: "2px" }}>
                {idx.toString().padStart(2, "0")}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(timeMap).map(day => (
            <TableRow key={day}>
              <DayHeaderCell onClick={() => weekDayClick(day)}>
                {t(`form.weekDays.${day}`)}
              </DayHeaderCell>
              {Array.from({ length: 24 }).map((_, hour) => {
                const id = `${day}-${hour}`;
                return (
                  <HighlightedTableCell
                    key={id}
                    className={timeMap[day].includes(hour) ? "highlighted" : ""}
                    onMouseDown={event => handleMouseDown(event, day, hour)}
                    onMouseOver={event => handleMouseOver(event, day, hour)}
                  ></HighlightedTableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default SchedulerForm;
