import { Auth } from "api/AuthAPI";
import { allMenus, permittedMenus } from "contexts/MainMenuContext";

const READ = "read";
const WRITE = "write";

type PermissionType = typeof READ | typeof WRITE; // WRITE supersedes READ, as in, WRITE can also READ

type Permissions = Record<string, Record<string, PermissionType>>;

const permissions: Permissions = {
  CLONE_DASHBOARD: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": WRITE,
    "Administrador/Coordenador": READ
  },
  CONVOY_DASHBOARD: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": WRITE,
    "Administrador/Coordenador": READ
  },
  RESTRICTION_DASHBOARD: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": WRITE,
    "Administrador/Coordenador": READ
  },
  MONITORING_VEHICLES_DASHBOARD: {
    // analytics
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Cliente/Convidado": READ,
    //"Cliente/Móvel": READ, // FIXME none set in dynamodb
    "Cliente/Usuário": WRITE,
    //"Cliente/VideoWall": READ, // FIXME none set in dynamodb
    //"Administrador/Auditor": READ, // FIXME none set in dynamodb
    "Administrador/Coordenador": READ
    //"Administrador/Financeiro": READ // FIXME none set in dynamodb
  },
  CAMERA_DASHBOARD: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": WRITE,
    "Administrador/Coordenador": READ
  },
  FLOW_DASHBOARD: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": WRITE,
    "Administrador/Coordenador": READ
  },
  SYSTEM_MENU: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": WRITE,
    "Administrador/Coordenador": READ
  },
  MONITORING_MENU: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": WRITE,
    "Administrador/Coordenador": READ
  },
  MOSAIC: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": WRITE,
    "Administrador/Coordenador": READ
  },
  EQUIPMENT_MAP: {
    "Pumatronix/Master": READ,
    "Pumatronix/Suporte": READ,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  },
  MONITORING_VEHICLES: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Administrador/Coordenador": READ
  },
  VEHICLE_OVERVIEW: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Administrador/Coordenador": READ
  },
  RESTRICTION: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Administrador/Coordenador": READ
  },
  MONITORED_ROUTES: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Administrador/Coordenador": READ
  },
  EQUIPMENTANDCAMERA_MENU: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": WRITE,
    "Administrador/Coordenador": READ
  },
  EQUIPMENTS: {
    // location, location/list, location/partial (read)
    // location/create, location/update, location/delete (write)
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  },
  CAMERAS: {
    // camera, camera/list, camera/partial (read)
    // camera/create, camera/update, camera/delete (write)
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  },
  REPORTS_MENU: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": WRITE,
    "Administrador/Coordenador": READ
  },
  DASHBOARDS_MENU: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": WRITE,
    "Administrador/Coordenador": READ
  },
  CAPTURE_REPORT: {
    // register/list, register/download (read)
    "Pumatronix/Master": READ,
    "Pumatronix/Suporte": READ,
    "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  },
  CONVOY_REPORT: {
    // convoy/list, convoy/download (read)
    "Pumatronix/Master": READ,
    "Pumatronix/Suporte": READ,
    "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  },
  FORENSIC_REPORT: {
    // register/list, register/download (read)
    "Pumatronix/Master": READ,
    "Pumatronix/Suporte": READ,
    "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  },
  INCIDENT_REPORT: {
    // register/list, register/download (read)
    "Pumatronix/Master": READ,
    "Pumatronix/Suporte": READ,
    "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  },
  CORRELATION_REPORT: {
    "Pumatronix/Master": READ,
    "Pumatronix/Suporte": READ,
    "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  },
  MONITORING_REPORT: {
    // report/monitoring-plate, report/download-monitoring-plate (read)
    "Pumatronix/Master": READ,
    "Pumatronix/Suporte": READ,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  },
  SPIA_REPORT: {
    "Pumatronix/Master": READ,
    "Pumatronix/Suporte": READ,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  },
  LOG_REPORT: {
    // logs (read)
    "Pumatronix/Master": READ,
    "Pumatronix/Suporte": READ,
    "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  },
  SETTINGS_MENU: {
    "Pumatronix/Master": READ,
    "Pumatronix/Suporte": READ,
    // "Cliente/Convidado": READ,
    // "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  },
  ACCESSCONTROL_MENU: {
    "Pumatronix/Master": READ,
    "Pumatronix/Suporte": READ,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  },
  USERS: {
    // user, user/list, user/partial (read)
    // user/update (write)
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Administrador/Coordenador": READ
  },
  USER_GROUPS: {
    // user-group, user-group/list, user-group/partial (read)
    // user-group/create, user-group/update, user-group/delete (write)
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Administrador/Coordenador": READ
  },
  PROFILES: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Administrador/Coordenador": READ
  },
  SHARED_DATA: {
    // user-group, user-group/list, user-group/partial (read)
    // user-group/create, user-group/update, user-group/delete (write)
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Administrador/Coordenador": READ
  },
  CUSTOMERS: {
    // customer, customer/list, customer/partial (read)
    // customer/create, customer/update, customer/delete (write)
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE
  },
  VERTICALS: {
    // vertical, vertical/list (read)
    // vertical/create, vertical/update, vertical/delete (write)
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Administrador/Coordenador": READ
  },
  BILLING_MENU: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Administrador/Coordenador": READ
  },
  BILLING: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Administrador/Coordenador": READ
  },
  INVOICE: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Administrador/Coordenador": READ
  },
  CUSTOMIZATION_MENU: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    // "Cliente/Convidado": READ,
    // "Cliente/Usuário": WRITE,
    "Administrador/Coordenador": READ
  },
  CUSTOMIZATION: {
    // customization (read)
    // customization/set (write)
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    // "Cliente/Convidado": READ,
    // "Cliente/Usuário": READ,
    "Administrador/Coordenador": WRITE
  },
  INTEGRATIONS: {
    // integration/*
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    // "Cliente/Usuário": WRITE,
    "Administrador/Coordenador": READ
  },
  INFORMATION_PROTECTION: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Administrador/Coordenador": WRITE
  },
  USER_GUIDE: {
    "Pumatronix/Master": READ,
    "Pumatronix/Suporte": READ,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  },
  USER_AGREEMENT: {
    "Pumatronix/Master": READ,
    "Pumatronix/Suporte": READ,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  },
  SYSTEM_FILTER_CAMERAS: {
    "Pumatronix/Master": READ,
    "Pumatronix/Suporte": READ,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  }
};

// Pages that are not on the menu but should be permitted
const permittedNonMenuPages = [
  "VEHICLE_OVERVIEW",
  "USER_GUIDE",
  "USER_AGREEMENT",
  "SYSTEM_FILTER_CAMERAS"
];

export const canAccess = (
  feature: string,
  user: Auth["user"] | null,
  type: PermissionType
): boolean => {
  if (!user) return false;

  const permitted = [
    ...allMenus(permittedMenus(user)),
    ...permittedNonMenuPages
  ];
  if (!permitted.includes(feature)) return false;

  const featurePermissions = permissions[feature];
  if (!featurePermissions) return false;

  const userLevel = user["level_name"];
  if (!userLevel) return false;

  const userSubLevels = user["sub_level_name"];
  if (!userSubLevels || userSubLevels.length < 1) return false;

  for (const userSubLevel of userSubLevels) {
    const fullLevel = `${userLevel}/${userSubLevel}`;
    const permission = featurePermissions[fullLevel];
    if (permission === WRITE || permission === type) {
      return true;
    }
  }

  return false;
};

export const canRead = (feature: string, user: Auth["user"] | null): boolean =>
  canAccess(feature, user, READ);

export const canWrite = (feature: string, user: Auth["user"] | null): boolean =>
  canAccess(feature, user, WRITE);

export default Permissions;
