import Pages from "enums/Pages";
import useCameraAPI, { CameraFilter } from "api/CameraAPI";
import fileDownload from "js-file-download";
import LinkPlate from "components/LinkPlate";
import { useIsMount } from "hooks/useIsMount";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import IncidentReportFilter, {
  FormFilterIncident
} from "./IncidentReportFilter";
import { endOfDay, format, parseISO, startOfDay } from "date-fns";
import { Download, Sliders } from "react-feather";
import { useLocale } from "contexts/LocaleContext";
import InnerPageLayout from "layouts/InnerPageLayout";
import DataTable from "components/DataTable";
import { useHistory, useParams } from "react-router-dom";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import useLocationAPI, { Location } from "api/LocationAPI";
import CaptureImgButton from "components/CaptureImgButton";
import { FC, useCallback, useEffect, useState } from "react";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { formatDateTime, setTimezone } from "utils/DateFunctions";
import IncidentReportViewDialog, {
  IncidentDialog,
  initialIncidentImageDialogProps
} from "components/IncidentReportDialog/IncidentReportDialog";
import useToolsAPI from "api/ToolsAPI";
import useIncidentReportAPI, {
  IncidentReport,
  VehiclesMake
} from "api/IncidentReportAPI";
import { LocationSection, usePageLocation } from "contexts/PageLocationContext";
import { parsePlateCoordinate } from "components/ImageViewer";
import CustomFilterKeys from "enums/CustomFilterKeys";
import { CustomFilterProvider } from "components/CustomFilterMenu/CustomFilterContext";
import RestrictionTypeBox from "components/TypeBox";
import { EMPTY_VALUE, setRestrictionLabelColor } from "utils/String";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";
import MenuButton from "components/MenuButton";
import PageSection from "components/PageSection/PageSection";
import {
  PaginatorModel,
  defaultPaginatorModelValues
} from "components/Paginator";

const IncidentReportPage: FC = () => {
  const [isFilterOpen, setFilterOpen] = useState(true);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isDownloadingCsv, setDownloadingCsv] = useState<boolean>(false);
  const [isDownloadingPdf, setDownloadingPdf] = useState<boolean>(false);
  const [reportResults, setReportResults] = useState<IncidentReport[]>([]);
  const [page, setPage] = useState(1);
  const [paginator, setPaginator] = useState<PaginatorModel>(
    defaultPaginatorModelValues
  );
  const { sessionUser } = useAuth();
  const { language } = useLocale();
  const LocationAPI = useLocationAPI();
  const IncidentReportAPI = useIncidentReportAPI();
  const CameraAPI = useCameraAPI();
  const ToolsAPI = useToolsAPI();
  const { t } = useTranslation();
  const { errorHandler } = useErrorHandler();

  const params = useParams<{ plate: string }>();
  const plate = params.plate ? decodeURIComponent(params.plate) : "";
  const [cameras, setCameras] = useState<CameraFilter[]>([]);
  const [equipmentsName, setEquipmentsName] = useState<string[]>([]);
  const [equipments, setEquipments] = useState<Location[]>([]);
  const [makes, setMakes] = useState<VehiclesMake[]>([]);

  useEffect(() => {
    (async () => {
      if (sessionUser) {
        const customerId = sessionUser["customer_id"];
        try {
          const [locationResponse, cameraResponse, makeResponse] =
            await Promise.all([
              LocationAPI.listAll(customerId),
              CameraAPI.listAll({ customerId }),
              ToolsAPI.listAllMakes()
            ]);

          const liteMake = makeResponse.make
            .filter(item => item.lite === true)
            .map(item => ({ name: item.name, isDivider: false }));
          liteMake[liteMake.length - 1].isDivider = true;
          const nonLiteMake = makeResponse.make
            .filter(item => item.lite === false)
            .map(item => ({ name: item.name, isDivider: false }));
          const makes = liteMake.concat(nonLiteMake);

          setMakes(makes || []);

          setCameras(
            [
              t("form.all"),
              ...cameraResponse.data.map(camera => ({
                ["location_name"]: camera["location_name"],
                ["camera_name"]: camera["camera_name"]
              }))
            ] || []
          );
          setEquipmentsName(
            [
              t("form.all"),
              ...locationResponse.data.map(
                location => location["location_name"]
              )
            ] || []
          );
          setEquipments(locationResponse.data);
        } catch (error) {
          errorHandler({ error });
        }
      }
    })();
  }, []);

  const defaultDate = new Date();
  const defaultFilterValues: FormFilterIncident = {
    restrictions: [],
    equipment: [],
    cameras: [],
    plate: "",
    startDate: setTimezone(startOfDay(defaultDate)),
    endDate: setTimezone(defaultDate),
    startTime: setTimezone(startOfDay(defaultDate)),
    endTime: setTimezone(endOfDay(defaultDate)),
    make: "",
    model: "",
    color: ""
  };

  const [filter, setFilter] = useState<FormFilterIncident>({
    ...defaultFilterValues,
    plate: plate || defaultFilterValues.plate,
    startDate: plate ? null : defaultFilterValues.startDate,
    endDate: plate ? null : defaultFilterValues.endDate,
    startTime: plate ? null : defaultFilterValues.startTime,
    endTime: plate ? null : defaultFilterValues.endTime
  });

  const history = useHistory();

  const requestData = useCallback(
    async (
      filterParam: FormFilterIncident,
      pageValue: number,
      pageSizeValue: number
    ) => {
      if (!sessionUser?.["customer_id"]) return;
      const customerId = sessionUser["customer_id"];
      setLoading(true);
      try {
        if (
          !filterParam.plate &&
          history.location.pathname !== Pages.INCIDENT_REPORT
        ) {
          history.replace(Pages.INCIDENT_REPORT);
        }
        if (!plate && filterParam.plate) {
          history.replace(
            `${Pages.INCIDENT_REPORT}/${encodeURIComponent(filterParam.plate)}`
          );
        }

        const reportResponse = await IncidentReportAPI.list({
          ["customer_id"]: customerId,
          ["initial_date"]: filterParam.startDate
            ? formatDateTime(
                filterParam.startDate as Date,
                filterParam.startTime as Date
              )
            : undefined,
          ["final_date"]: filterParam.endDate
            ? formatDateTime(
                filterParam.endDate as Date,
                filterParam.endTime as Date
              )
            : undefined,
          plate: filterParam.plate,
          ["restrictions"]: filterParam.restrictions,
          cameras: filterParam.cameras,
          equipments: filterParam.equipment,
          page: pageValue,
          ["vehicle_make"]: filterParam.make,
          ["vehicle_model"]: filterParam.model,
          ["vehicle_color"]: filterParam.color,
          ["page_size"]: pageSizeValue
        });
        setReportResults(reportResponse.registers.items || []);
        setPaginator({
          totalPages: reportResponse.registers.total_pages || 0,
          totalItems: reportResponse.registers.total_items || 0
        });
        setPage(reportResponse.registers.page || 1);
      } catch (error) {
        errorHandler({ error });
      } finally {
        setLoading(false);
      }
    },
    [sessionUser]
  );

  const onFilter = (filterValue: FormFilterIncident) => {
    setFilter(filterValue);
  };

  const isMount = useIsMount();

  useEffect(() => {
    requestData(filter, page, pageSize);
  }, [requestData, pageSize]);

  useEffect(() => {
    if (!isMount && plate !== filter?.plate) {
      onFilter({
        ...filter,
        plate: plate || defaultFilterValues.plate,
        startDate: plate ? null : defaultFilterValues.startDate,
        endDate: plate ? null : defaultFilterValues.endDate,
        startTime: plate ? null : defaultFilterValues.startTime,
        endTime: plate ? null : defaultFilterValues.endTime
      });
    }
  }, [plate]);

  useEffect(() => {
    if (!isMount) {
      if (filter === null) {
        requestData(filter, page, pageSize);
      } else {
        requestData(filter, 1, pageSize);
      }
    }
  }, [filter]);

  const downloadCsv = async () => {
    if (!filter || isDownloadingCsv || !sessionUser?.["customer_id"]) return;
    const customerId = sessionUser["customer_id"];
    try {
      setDownloadingCsv(true);
      const response = await IncidentReportAPI.downloadCsv({
        ["customer_id"]: customerId,
        ["initial_date"]: filter.startDate
          ? formatDateTime(filter.startDate as Date, filter.startTime as Date)
          : undefined,
        ["final_date"]: filter.endDate
          ? formatDateTime(filter.endDate as Date, filter.endTime as Date)
          : undefined,
        plate: filter.plate ?? undefined,
        ["restrictions"]: filter.restrictions?.length
          ? filter.restrictions
          : undefined,
        cameras: filter.cameras?.length ? filter.cameras : undefined,
        equipments: filter.equipment?.length ? filter.equipment : undefined,
        ["vehicle_make"]: filter.make ?? undefined,
        ["vehicle_model"]: filter.model ?? undefined,
        ["vehicle_color"]: filter.color ?? undefined,
        language: language.split("-")[0]
      });
      fileDownload(response, "INCIDENT_REPORT.csv", "text/csv");
    } catch (error) {
      errorHandler({ error });
    } finally {
      setDownloadingCsv(false);
    }
  };

  const forceDownloadPdf = (pdf: string) => {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "INCIDENT_REPORT.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const downloadPdf = async () => {
    if (!filter || isDownloadingPdf || !sessionUser?.["customer_id"]) return;
    const customerId = sessionUser["customer_id"];
    try {
      setDownloadingPdf(true);
      const response = await IncidentReportAPI.downloadPdf({
        ["customer_id"]: customerId,
        ["initial_date"]: filter.startDate
          ? formatDateTime(filter.startDate as Date, filter.startTime as Date)
          : undefined,
        ["final_date"]: filter.endDate
          ? formatDateTime(filter.endDate as Date, filter.endTime as Date)
          : undefined,
        plate: filter.plate ?? undefined,
        ["restrictions"]: filter.restrictions?.length
          ? filter.restrictions
          : undefined,
        cameras: filter.cameras?.length ? filter.cameras : undefined,
        equipments: filter.equipment?.length ? filter.equipment : undefined,
        ["vehicle_make"]: filter.make ?? undefined,
        ["vehicle_model"]: filter.model ?? undefined,
        ["vehicle_color"]: filter.color ?? undefined,
        language: language.split("-")[0]
      });
      forceDownloadPdf(response);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setDownloadingPdf(false);
    }
  };

  const [incident, setIncident] = useState<IncidentDialog>(
    initialIncidentImageDialogProps
  );
  const [isIncidentDialogOpen, setIncidentDialogOpen] =
    useState<boolean>(false);

  const getIncidentReportCoord = (incident: IncidentReport) => {
    // Check IncidentReport first
    if (incident.latitude && incident.longitude) {
      return { latitude: incident.latitude, longitude: incident.longitude };
    }
    // Search equipments list
    const coord = equipments.find(
      item => item?.["location_name"] === incident?.equipment
    );
    return {
      latitude: coord?.["location_data"]["latitude"] ?? "",
      longitude: coord?.["location_data"]["longitude"] ?? ""
    };
  };

  const openIncidentDialog = (incident: IncidentReport) => {
    const coord = getIncidentReportCoord(incident);
    setIncident({
      restriction: incident["restriction_type"],
      id: incident.id,
      image: incident.imageLink,
      equipment: incident.equipment,
      camera: incident.camera,
      dateTime: incident["date_capture"],
      plate: incident.plate,
      plateCoordinate: incident.plate_coordinate
        ? parsePlateCoordinate(incident.plate_coordinate)
        : undefined,
      longitude: coord["longitude"],
      latitude: coord["latitude"],
      renavam: {
        vehicleMake: incident.vehicle_make,
        vehicleModel: incident.vehicle_model,
        vehicleColor: incident.vehicle_color
      },
      city: incident.city,
      state: incident.state
    });
    setIncidentDialogOpen(true);
  };

  const { setPageTitle, setLocation } = usePageLocation();

  useEffect(() => {
    const title = plate
      ? t("windowTitle.incidentReportPlate", { plate })
      : t("windowTitle.incidentReport");
    setPageTitle(title);
    const location: LocationSection[] = [
      {
        label: t("menu.reports")
      },
      {
        label: t("IncidentReportPage.title"),
        page: Pages.INCIDENT_REPORT
      }
    ];
    if (plate) {
      location.push({
        label: plate,
        page: `${Pages.INCIDENT_REPORT}/${encodeURIComponent(plate)}`
      });
    }
    setLocation(location);
  }, [t, plate, Pages]);

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <PageSection
          title={t("IncidentReportPage.title")}
          isLoading={isLoading}
          actions={
            <MenuButton
              id="download-button"
              disabled={paginator.totalItems < 1}
              icon={<Download />}
              label={t("action.export")}
              actions={[
                {
                  key: "downloadCsv",
                  isLoading: isDownloadingCsv,
                  label: t("CaptureReportPage.exportCsv"),
                  onClick: downloadCsv
                },
                {
                  key: "downloadPdf",
                  isLoading: isDownloadingPdf,
                  label: t("CaptureReportPage.exportPdf"),
                  onClick: downloadPdf
                }
              ]}
            />
          }
          primaryActions={
            <>
              <PageSectionHeaderAction
                variant="contained"
                color="secondary"
                startIcon={<Sliders />}
                onClick={() => setFilterOpen(true)}
              >
                {t("action.filter")}
              </PageSectionHeaderAction>
            </>
          }
        >
          <DataTable
            watermarked
            headers={[
              { key: "id", label: t("IncidentReportPage.id") },
              { key: "date_capture", label: t("IncidentReportPage.datetime") },
              {
                key: "incidentType",
                label: t("IncidentReportPage.incidentType")
              },
              { key: "camera", label: t("IncidentReportPage.camera") },
              { key: "plate", label: t("IncidentReportPage.plate") },
              { key: "city", label: t("IncidentReportPage.city") },
              { key: "state", label: t("IncidentReportPage.state") },
              {
                key: "capture",
                label: t("IncidentReportPage.capture"),
                noSort: true
              }
            ]}
            defaultSort={["id", "asc"]}
            onHeaderSort={setReportResults}
            data={reportResults}
            renderRow={row => [
              <>{row.id}</>,
              <>
                {format(
                  parseISO(row["date_capture"]),
                  t("IncidentReportPage.dateFormat")
                )}
              </>,
              <>
                <RestrictionTypeBox
                  text={t(`RestrictionsPage.type.${row.restriction_type}`)}
                  color={setRestrictionLabelColor(row.restriction_type)}
                  align="flex-start"
                />
              </>,
              <>{row.camera}</>,
              <>
                <LinkPlate plate={row.plate} path={Pages.INCIDENT_REPORT} />
              </>,
              <>{row.city === "ND" || !row.city ? EMPTY_VALUE : row.city}</>,
              <>{row.state === "ND" || !row.state ? EMPTY_VALUE : row.state}</>,
              <>
                <CaptureImgButton
                  imageLink={row.imageLink}
                  onClick={() => openIncidentDialog(row)}
                />
              </>
            ]}
            hideColumnsSm={[5, 6, 7]}
            hideColumnsXs={[0, 2, 3, 5, 6, 7]}
            page={page}
            onPageChange={pageValue => requestData(filter, pageValue, pageSize)}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            totalPages={paginator.totalPages}
            totalItems={paginator.totalItems}
            isLoading={isLoading}
          />
        </PageSection>
      </InnerPageLayout>
      <CustomFilterProvider filterKey={CustomFilterKeys.INCIDENT_REPORT}>
        <IncidentReportFilter
          open={isFilterOpen}
          cameras={cameras}
          makes={makes}
          equipments={equipmentsName}
          setOpen={setFilterOpen}
          filter={filter}
          defaultValues={defaultFilterValues}
          onFilter={onFilter}
        />
      </CustomFilterProvider>
      <IncidentReportViewDialog
        open={isIncidentDialogOpen}
        setOpen={setIncidentDialogOpen}
        incident={incident}
      />
    </DefaultPageLayout>
  );
};

export default IncidentReportPage;
