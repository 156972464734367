import { AxiosResponse } from "axios";
import APIEndpoints from "enums/APIEndpoints";
import useAxios from "hooks/useAxios";

export type EquipmentLocation = {
  ["location_name"]: string;
  latitude: number;
  longitude: number;
  address: string;
  status: "disabled" | "error" | "offline" | "online";
  updated: string;
  ["camera_data"]: {
    ["camera_name"]: string;
    ["serial_number"]: string;
    active: boolean;
  }[];
};

export type Address = {
  address: string;
  latitude: string;
  longitude: string;
};

type UseMapsAPIType = {
  listAllByCustomer: (customerId: string) => Promise<EquipmentLocation[]>;
  translateAddress: (
    customerId: string,
    address: string,
    addressNumber: string,
    city: string
  ) => Promise<Address>;
};

const useMapsAPI = (): UseMapsAPIType => {
  const API = useAxios();

  const listAllByCustomer = async (
    customerId: string
  ): Promise<EquipmentLocation[]> => {
    const response: AxiosResponse<EquipmentLocation[]> = await API.instance.get(
      APIEndpoints.MAPS_CUSTOMER,
      {
        params: { ["customer_id"]: customerId }
      }
    );
    return response.data;
  };

  const translateAddress = async (
    customerId: string,
    address: string,
    addressNumber: string,
    city: string
  ): Promise<Address> => {
    const response: AxiosResponse<Address> = await API.instance.post(
      APIEndpoints.MAPS_TRANSLATE,
      {
        ["customer_id"]: customerId,
        address,
        ["address_number"]: addressNumber,
        city,
        country: "Brasil"
      }
    );
    return response.data;
  };

  return {
    translateAddress,
    listAllByCustomer
  };
};

export default useMapsAPI;
