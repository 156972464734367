import { Box, Typography } from "@mui/material";
import React from "react";
import debounce from "lodash/debounce";
import useProfileAPI from "api/ProfileAPI";
import { useIsMount } from "hooks/useIsMount";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import snackNotification from "components/SnackNotification";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import useUserAPI, { DataUserCustomerList } from "api/UserAPI";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DataTable from "components/DataTable";
import Checkbox from "@mui/material/Checkbox";
import {
  PaginatorModel,
  defaultPaginatorModelValues
} from "components/Paginator";
import InputField from "components/InputField";
import { Search } from "react-feather";
import TableSortLabel from "@mui/material/TableSortLabel";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  updateCallerContent: (newUsers: string[]) => void;
  profile: string;
  profileUsers: string[];
};

const AddProfileDialog: FC<Props> = ({
  open,
  setOpen,
  updateCallerContent,
  profile,
  profileUsers
}) => {
  const { t } = useTranslation();
  const isMount = useIsMount();
  const { sessionUser } = useAuth();
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const { errorHandler } = useErrorHandler();
  const UserAPI = useUserAPI();
  const [page, setPage] = useState(1);
  const [selectAll, setSelectAll] = useState(false);
  const [paginator, setPaginator] = useState<PaginatorModel>(
    defaultPaginatorModelValues
  );
  const { updateUserProfile } = useProfileAPI();
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<string>("username");

  const requestData = useCallback(
    async (searchValue, pageValue) => {
      if (!sessionUser?.["customer_id"]) return;
      setIsLoading(true);
      let users: string[] = [];
      try {
        const userResponse = await UserAPI.search(
          sessionUser["customer_id"],
          searchValue.length > 0 ? searchValue : "@",
          pageValue
        );
        const userData = userResponse.data as DataUserCustomerList;
        setPaginator({
          totalPages: userData["total_pages"] || 0,
          totalItems: userData["total_count"] || 0
        });
        users = userData.customers.map(user => user.username);
        users = users.filter(user => !profileUsers?.includes(user));
        users.sort((a, b) => {
          if (order === "asc") return a.localeCompare(b);
          return b.localeCompare(a);
        });
      } catch (error) {
        errorHandler({ error });
      } finally {
        setUsers(users || []);
        setIsLoading(false);
      }
    },
    [sessionUser, profileUsers, order]
  );

  useEffect(() => {
    requestData(search, 1);
  }, [requestData, open]);

  const fetch = useMemo(
    () =>
      debounce((searchValue: string) => {
        requestData(searchValue, 1);
      }, 700),
    []
  );

  useEffect(() => {
    if (!isMount) {
      fetch(search);
    }
  }, [search, fetch]);

  const addUsers = async () => {
    if (!sessionUser?.["customer_id"]) return;
    setIsLoading(true);
    snackNotification.close();
    const newUsers: string[] = [];
    if (profile) {
      try {
        await Promise.all(
          selectedUsers.map(async user => {
            const updateData = {
              customerId: sessionUser["customer_id"],
              profile,
              username: user
            };
            await updateUserProfile(updateData);
            newUsers.push(user);
          })
        );
      } catch (error) {
        errorHandler({ error });
      } finally {
        snackNotification.success(t("EditUserGroupsPage.usersAdded"));
        updateCallerContent(newUsers);
        setIsLoading(false);
      }
    }
    setOpen(false);
  };

  const handleUserSelect = (username: string) => {
    if (username === "all") {
      if (selectAll) {
        setSelectedUsers([]);
      } else {
        setSelectedUsers(users.map(user => user));
      }
      setSelectAll(!selectAll);
    } else {
      setSelectedUsers(prevSelectedUsers => {
        if (prevSelectedUsers.includes(username)) {
          return prevSelectedUsers.filter(user => user !== username);
        }
        return [...prevSelectedUsers, username];
      });
    }
  };

  const handleConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    addUsers();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="md">
      <DialogTitle>{`${t("ProfilesPage.addUsers")} ${profile}`}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflow: "hidden"
          }}
        >
          <InputField
            gutter={false}
            customProps={{
              placeholder: t("EditUserGroupsPage.searchUser"),
              onChange: event => setSearch(event.target.value),
              startAdornment: <Search />,
              sx: {
                marginBottom: "16px"
              }
            }}
          />
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              marginBottom: "16px"
            }}
          >
            <DataTable
              headers={[
                {
                  key: "select",
                  label: (
                    <Checkbox
                      checked={selectAll}
                      onChange={() => handleUserSelect("all")}
                      indeterminate={
                        selectedUsers.length > 0 &&
                        selectedUsers.length < users.length
                      }
                      sx={{
                        padding: 0,
                        "&.MuiCheckbox-root": {
                          marginTop: "-8px",
                          marginBottom: "-8px"
                        }
                      }}
                    />
                  )
                },
                {
                  key: "username",
                  label: (
                    <TableSortLabel
                      active={orderBy === "username"}
                      direction={orderBy === "username" ? order : "asc"}
                      onClick={() => handleRequestSort("username")}
                    >
                      {t("UsersPage.users")}
                    </TableSortLabel>
                  ),
                  noSort: true
                }
              ]}
              defaultSort={["username", "asc"]}
              data={users.map(user => ({ key: user }))}
              renderRow={user => [
                <Checkbox
                  key={`checkbox-${user.key}`}
                  checked={selectedUsers.includes(user.key)}
                  onChange={() => handleUserSelect(user.key)}
                  disabled={profileUsers?.includes(user.key)}
                  sx={{
                    padding: 0,
                    "&.MuiCheckbox-root": {
                      marginTop: "-8px",
                      marginBottom: "-8px"
                    }
                  }}
                />,
                <Typography component="div" variant="caption" key={user.key}>
                  <strong>{user.key}</strong>
                </Typography>
              ]}
              hideColumnsSm={[2, 3]}
              hideColumnsXs={[1, 2, 3]}
              page={page}
              onPageChange={pageValue => {
                setPage(pageValue);
                requestData(search, pageValue);
              }}
              pageSize={10}
              totalPages={paginator.totalPages}
              totalItems={paginator.totalItems}
              isLoading={isLoading}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary" variant="outlined">
          {t("action.cancel")}
        </Button>
        <Button
          onClick={handleConfirm}
          color="secondary"
          variant="contained"
          disabled={selectedUsers.length === 0 || isLoading}
        >
          {t("action.add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddProfileDialog;
