import {
  Box,
  Grid,
  styled,
  Typography,
  TextField,
  Autocomplete,
  Divider
} from "@mui/material";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import InputField from "components/InputField";
import { Controller, useForm } from "react-hook-form";
import { FC, useEffect, useState } from "react";
import useToolsAPI, { City, SpiaPrfRestriction, State } from "api/ToolsAPI";
import { useAuth } from "contexts/AuthContext";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { useIsMount } from "hooks/useIsMount";
import Drawer from "components/Drawer";
import FormLabel from "components/FormLabel";

const ContentContainer = styled(Box)(() => ({
  height: "calc(100vh - 200px)",
  overflow: "auto"
}));

const Content = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(0)} ${theme.spacing(4)} ${theme.spacing(
    4
  )} ${theme.spacing(4)}`,
  maxHeight: "100%"
}));

const Footer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  display: "flex",
  justifyContent: "flex-end",
  borderTop: `1px solid ${theme.palette.grey["200"]}`,
  "& button": {
    marginLeft: theme.spacing(1)
  }
}));

export type FormFilterSpiaReport = {
  plate: string;
  state: string;
  city: string[];
  restrictions: string[];
};

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  onFilter: (data: FormFilterSpiaReport) => void;
};

const SpiaReportFilter: FC<Props> = ({ open, setOpen, onFilter }) => {
  const defaultValues: FormFilterSpiaReport = {
    plate: "",
    state: "",
    city: [],
    restrictions: []
  };

  const { t } = useTranslation();

  const { control, handleSubmit, reset } = useForm<FormFilterSpiaReport>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues
  });

  const clearForm = () => {
    setSelectedState(null);
    setSelectedCity([]);
    setSelectedRestrictions([]);
    reset();
  };

  const onSubmit = async (data: FormFilterSpiaReport) => {
    onFilter({
      ...data,
      state: selectedState?.id ?? "",
      city: selectedCity?.map(city => city.name) ?? [],
      restrictions: selectedRestrictions?.map(rest => rest.code) ?? []
    });
    setOpen(false);
  };

  const [states, setStates] = useState<State[]>([]);
  const [selectedState, setSelectedState] = useState<State | null>(null);
  const [isLoadingStates, setLoadingStates] = useState(false);
  const [cities, setCities] = useState<City[]>([]);
  const [selectedCity, setSelectedCity] = useState<City[]>([]);
  const [isLoadingCities, setLoadingCities] = useState(false);
  const [restrictions, setRestrictions] = useState<SpiaPrfRestriction[]>([]);
  const [selectedRestrictions, setSelectedRestrictions] = useState<
    SpiaPrfRestriction[]
  >([]);
  const [isLoadingRestrictions, setLoadingRestrictions] = useState(false);

  const { sessionUser } = useAuth();
  const ToolsAPI = useToolsAPI();
  const { errorHandler } = useErrorHandler();
  const isMount = useIsMount();

  const getStates = async () => {
    if (!sessionUser?.["customer_id"]) return;
    setLoadingStates(true);
    try {
      const countryResponse = await ToolsAPI.getCountry("Brasil");
      setStates(countryResponse.data[0].states);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setLoadingStates(false);
    }
  };

  const getRestrictions = async () => {
    if (!sessionUser?.["customer_id"]) return;
    setLoadingRestrictions(true);
    try {
      const response = await ToolsAPI.getSpiaPrfRestrictions();
      const data = response.data[0].data.toSorted((a, b) =>
        a.code.localeCompare(b.code)
      );
      setRestrictions(data);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setLoadingRestrictions(false);
    }
  };

  useEffect(() => {
    if (open && states.length < 1) {
      getStates();
      getRestrictions();
    }
  }, [open]);

  const getCities = async (state: State | null) => {
    if (!sessionUser?.["customer_id"]) return;
    if (state === null) {
      setCities([]);
      setSelectedCity([]);
      return;
    }
    setLoadingCities(true);
    try {
      const citiesResponse = await ToolsAPI.getCities("Brasil", state.name);
      setCities(citiesResponse.data[0].cities);
      setSelectedCity([]);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setLoadingCities(false);
    }
  };

  useEffect(() => {
    if (!isMount && selectedState) {
      getCities(selectedState);
    } else if (selectedState === null) {
      setSelectedCity([]);
      setCities([]);
    }
  }, [selectedState]);

  return (
    <Drawer open={open} setOpen={setOpen} title={t("CaptureReportPage.filter")}>
      <Divider sx={{ mb: 2 }} />
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <ContentContainer>
          <Content>
            <Grid container rowSpacing={3} columnSpacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="plate"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputField
                      uppercase
                      label={t("SpiaReportPage.plate")}
                      gutter={false}
                      field={{ ...field }}
                      fieldState={fieldState}
                      customProps={{
                        inputProps: {
                          maxLength: 7
                        }
                      }}
                    />
                  )}
                />
                <Typography
                  variant="caption"
                  align="left"
                  color="primary.light"
                >
                  {t("SpiaReportPage.tooltipPlate")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider>
                  <Typography variant="h5" color="primary.light">
                    {t("SpiaReportPage.locationSearch")}
                  </Typography>
                </Divider>
              </Grid>
              <Grid item xs={12}>
                <FormLabel>{t("SpiaReportPage.state")}</FormLabel>
                <Autocomplete
                  size="small"
                  disablePortal
                  openText={t("action.open")}
                  closeText={t("action.close")}
                  noOptionsText={t("form.noOptions")}
                  clearText={t("action.clear")}
                  options={states}
                  getOptionLabel={option => `${option.name} (${option.id})`}
                  disabled={isLoadingStates}
                  value={selectedState}
                  onChange={(event, value) => {
                    event.preventDefault();
                    setSelectedState(value);
                  }}
                  renderInput={params => (
                    <div style={{ position: "relative" }}>
                      <TextField
                        {...params}
                        required
                        placeholder={
                          isLoadingStates
                            ? t("waitState.loading")
                            : t("form.startTyping")
                        }
                      />
                    </div>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel>{t("SpiaReportPage.city")}</FormLabel>
                <Autocomplete
                  multiple
                  size="small"
                  disablePortal
                  openText={t("action.open")}
                  closeText={t("action.close")}
                  noOptionsText={t("form.noOptions")}
                  clearText={t("action.clear")}
                  options={cities}
                  getOptionLabel={option => option.name}
                  disabled={
                    isLoadingStates || isLoadingCities || !selectedState
                  }
                  value={selectedCity}
                  onChange={(event, value) => {
                    event.preventDefault();
                    setSelectedCity(value);
                  }}
                  renderInput={params => (
                    <div style={{ position: "relative" }}>
                      <TextField
                        {...params}
                        required
                        placeholder={
                          isLoadingCities
                            ? t("waitState.loading")
                            : selectedState
                            ? t("form.startTyping")
                            : t("SpiaReportPage.selectAState")
                        }
                      />
                    </div>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel>{t("SpiaReportPage.restrictions")}</FormLabel>
                <Autocomplete
                  multiple
                  size="small"
                  disablePortal
                  openText={t("action.open")}
                  closeText={t("action.close")}
                  noOptionsText={t("form.noOptions")}
                  clearText={t("action.clear")}
                  options={restrictions}
                  getOptionLabel={option => `${option.code} - ${option.name}`}
                  disabled={isLoadingRestrictions}
                  value={selectedRestrictions}
                  onChange={(event, value) => {
                    event.preventDefault();
                    setSelectedRestrictions(value);
                  }}
                  renderInput={params => (
                    <div style={{ position: "relative" }}>
                      <TextField
                        {...params}
                        required
                        placeholder={
                          isLoadingRestrictions
                            ? t("waitState.loading")
                            : t("form.startTyping")
                        }
                      />
                    </div>
                  )}
                />
              </Grid>
            </Grid>
          </Content>
        </ContentContainer>
        <Footer>
          <Button
            customProps={{
              color: "primary",
              variant: "outlined",
              onClick: clearForm
            }}
          >
            {t("action.clear")}
          </Button>
          <Button
            customProps={{
              type: "submit"
            }}
          >
            {t("action.filter")}
          </Button>
        </Footer>
      </form>
    </Drawer>
  );
};

export default SpiaReportFilter;
