import { Box, Typography } from "@mui/material";
import Pages from "enums/Pages";
import debounce from "lodash/debounce";
import { useIsMount } from "hooks/useIsMount";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import { useModal } from "contexts/ModalContext";
import InnerPageLayout from "layouts/InnerPageLayout";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import { usePageLocation } from "contexts/PageLocationContext";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { Edit, MinusCircle, Plus } from "react-feather";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import useProfileAPI, { Profile } from "api/ProfileAPI";
import DataList from "components/DataList";
import PageSection from "components/PageSection/PageSection";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";
import ProfileFormDialog from "./ProfileFormDialog";
import snackNotification from "components/SnackNotification";

const ProfilesPage: FC = () => {
  const isMount = useIsMount();
  const ProfileAPI = useProfileAPI();
  const { showModal } = useModal();
  const [isFetchingProfiles, setFetchingUsers] = useState<boolean>(true);
  const [pageSize, setPageSize] = useState<number>(10);
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [profilesFormOpen, setProfilesFormOpen] = useState(false);
  const { sessionUser } = useAuth();
  const { errorHandler } = useErrorHandler();
  const [defaultProfile, setDefaultProfile] = useState<Profile>({
    profile: "",
    permissions: []
  });

  const emptyProfile = (p: Profile) => ({
    ...p,
    permissions: p.permissions.map(permission => ({
      key: permission.key,
      subMenu: permission.subMenu.map(subMenu => ({
        key: subMenu.key,
        menu: []
      }))
    }))
  });

  const [selectedProfile, setSelectedProfile] = useState<Profile>(
    emptyProfile(defaultProfile)
  );

  const requestData = useCallback(async () => {
    if (!sessionUser?.["customer_id"]) return;
    setFetchingUsers(true);
    try {
      const response = await ProfileAPI.listAll({
        customerId: sessionUser["customer_id"]
      });

      setProfiles((response.data as Profile[]) || []);

      const permissions = await ProfileAPI.getDefault();

      setDefaultProfile({ ...emptyProfile(defaultProfile), permissions });
    } catch (error) {
      errorHandler({ error });
    } finally {
      setFetchingUsers(false);
    }
  }, [sessionUser]);

  const openProfileFormDialog = (name = "") => {
    setSelectedProfile(
      profiles.find(profile => profile.profile === name) ||
        emptyProfile(defaultProfile)
    );
    setProfilesFormOpen(true);
  };

  useEffect(() => {
    requestData();
  }, [requestData]);

  const fetch = useMemo(
    () =>
      debounce(() => {
        requestData();
      }, 700),
    []
  );

  useEffect(() => {
    if (!isMount) {
      fetch();
    }
  }, [fetch]);

  const handleRemoveProfile = (profileName: string) => {
    showModal({
      modalType: "confirm",
      onConfirm: () => removeProfile(profileName),
      confirmText: t("action.remove"),
      title: t("ProfilesPage.removeConfirmTitle", { profileName }),
      description: t("ProfilesPage.removeConfirmDescription")
    });
  };

  const removeProfile = async (name: string) => {
    if (!sessionUser?.["customer_id"]) return;
    try {
      await ProfileAPI.deleteByName({
        customerId: sessionUser["customer_id"],
        name
      });
    } catch (error) {
      errorHandler({ error });
    } finally {
      snackNotification.success(t("ProfilesPage.profileRemoved"));
      requestData();
    }
  };

  const { t } = useTranslation();
  const { setPageTitle, setLocation } = usePageLocation();

  useEffect(() => {
    setPageTitle(t("ProfilesPage.title"));
    setLocation([
      {
        label: t("menu.settings")
      },
      {
        label: t("menu.accessControl")
      },
      {
        label: t("ProfilesPage.title"),
        page: Pages.PROFILES
      }
    ]);
  }, [t, Pages]);

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <PageSection
          title={t("ProfilesPage.title")}
          isLoading={isFetchingProfiles}
          primaryActions={
            <PageSectionHeaderAction
              variant="contained"
              color="secondary"
              startIcon={<Plus />}
              onClick={() => openProfileFormDialog("")}
            >
              {t("ProfilesPage.new")}
            </PageSectionHeaderAction>
          }
        >
          <DataList
            watermarked
            data={profiles}
            renderName={row => (
              <Box onClick={() => openProfileFormDialog(row["profile"])}>
                <Typography variant="subtitle1">
                  <strong>{row["profile"]}</strong>
                </Typography>
              </Box>
            )}
            actions={row => [
              {
                tooltip: t("action.edit"),
                icon: Edit,
                onClick: () => openProfileFormDialog(row["profile"])
              },
              {
                tooltip: t("action.remove"),
                icon: MinusCircle,
                onClick: () => handleRemoveProfile(row["profile"])
              }
            ]}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            totalItems={profiles.length}
            isLoading={isFetchingProfiles}
          />
        </PageSection>
      </InnerPageLayout>
      <ProfileFormDialog
        profile={selectedProfile}
        open={profilesFormOpen}
        setOpen={setProfilesFormOpen}
        updateCallerContent={() => requestData()}
        defaultProfile={defaultProfile}
      />
    </DefaultPageLayout>
  );
};

export default ProfilesPage;
