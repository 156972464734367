import { AxiosResponse } from "axios";
import useAxios from "hooks/useAxios";
import APIEndpoints from "enums/APIEndpoints";
import { MonitoringType } from "./MonitoringAPI";
import { MapGeolocation } from "components/Map";

export type Grouping = "week" | "hour" | "all";

export type MonitoringVehiclesParams = {
  ["customer_id"]: string;
  ["initial_date"]: string;
  ["final_date"]: string;
  plate?: string | undefined;
  ["vehicle_make"]?: string | undefined;
  ["vehicle_model"]?: string | undefined;
  ["vehicle_color"]?: string | undefined;
  ["vehicle_class"]?: string[] | undefined;
  ["monitoring_reason"]?: MonitoringType[] | undefined;
  locations?: string[] | undefined;
  ["grouping_reasons"]?: Grouping | undefined;
  ["grouping_locations"]?: Grouping | undefined;
  page?: number;
  ["page_size"]?: number;
};

export type MonitoringReasonFrequency = {
  ["theft_and_robbery"]: number;
  documentation: number;
  intelligence: number;
  ["search_and_seizure"]: number;
  ["automatic_monitoring"]: number;
  none: number;
  total: number;
};

export type MonitoringReasonFrequencyHours = {
  hour: number;
};

export type MonitoringReasonFrequencyDays = {
  weekday: string;
};

export type MonitoringReason = {
  frequency: MonitoringReasonFrequency;
  ["frequency_by_weekday"]: (MonitoringReasonFrequency &
    MonitoringReasonFrequencyDays)[];
  ["frequency_by_hour"]: (MonitoringReasonFrequency &
    MonitoringReasonFrequencyHours)[];
};

export type MonitoredVehicles = {
  id: string;
  ["date_capture"]: string;
  plate: string;
  ["plate_coordinate"]: string;
  ["vehicle_class"]: string;
  characteristics: string;
  type: MonitoringType;
  ["camera_name"]: string;
  ["location_name"]: string;
  bucket: string;
  latitude: string;
  longitude: string;
  ["images_path"]: string;
  ["vehicle_make"]: string;
  ["vehicle_model"]: string;
  ["vehicle_color"]: string;
  ["vehicle_year"]: string;
};

export type VehiclesMake = {
  name: string;
  isDivider: boolean;
};

type WeekdayFrequency = {
  weekday: string;
  weight: number;
};

type LocationFrequencyDays = {
  location: string;
  ["weekday_frequency"]: WeekdayFrequency[];
};

type HourFrequency = {
  hour: number;
  weight: number;
};

type LocationFrequencyHours = {
  location: string;
  ["hour_frequency"]: HourFrequency[];
};

type LocationsScore = {
  ["frequency_by_weekday"]: LocationFrequencyDays[];
  ["frequency_by_hour"]: LocationFrequencyHours[];
};

type GeographicCoordinates = {
  latitude: string;
  longitude: string;
  type: string;
  weight: number;
};

type MonitoringVehiclesDatas = {
  ["geographic_coordinates"]: GeographicCoordinates[];
  ["geolocations"]: MapGeolocation[];
  ["monitoring_reasons"]: MonitoringReason;
  ["monitored_vehicles"]: {
    items: MonitoredVehicles[];
    page: number;
    ["total_items"]: number;
    ["total_pages"]: number;
  };
  ["locations_score"]: LocationsScore;
};

type MonitoringVehiclesResponse = {
  response: MonitoringVehiclesDatas;
};

type UseMonitoredVehiclesDashboardAPIType = {
  getMonitoredVehicles: (
    param: MonitoringVehiclesParams
  ) => Promise<MonitoringVehiclesDatas>;
};

const useMonitoredVehiclesDashboardAPI =
  (): UseMonitoredVehiclesDashboardAPIType => {
    const API = useAxios();

    const getMonitoredVehicles = async (
      filter: MonitoringVehiclesParams
    ): Promise<MonitoringVehiclesDatas> => {
      const response: AxiosResponse<MonitoringVehiclesResponse> =
        await API.instance.post(
          APIEndpoints.ANALYTICS_MONITORED_VEHICLES,
          filter
        );
      const geolocations: MapGeolocation[] =
        response.data.response.geographic_coordinates.map(geolocation => ({
          lat: Number(geolocation.latitude),
          lng: Number(geolocation.longitude),

          type: geolocation.type as
            | "documentation"
            | "intelligence"
            | "search_and_seizure"
            | "theft_and_robbery"
            | "automatic_monitoring"
            | "equipment"
            | undefined,
          weight: geolocation.weight
        }));

      return {
        ...response.data.response,
        geolocations
      };
    };
    return {
      getMonitoredVehicles
    };
  };
export default useMonitoredVehiclesDashboardAPI;
