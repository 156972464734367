import { AxiosResponse } from "axios";

import APIEndpoints from "enums/APIEndpoints";

import { Success } from "./API";
import { Location } from "./LocationAPI";
import { Camera } from "./CameraAPI";
import useAxios from "hooks/useAxios";

export type Mosaic = {
  mosaicData: {
    ["customer_id"]: string;
    username: string;
    size: string;
    ["mosaic_data"]: {
      location: Location["location_name"];
      camera: Camera["camera_name"];
      image?: string;
      date?: string;
      id?: string;
      plate?: string;
      type?: string;
      brand?: string;
      model?: string;
      color?: string;
      owner: string;
      latitude?: string;
      longitude?: string;
      serialNumber?: string;
    }[];
  };
};

type GetByUsernameParams = {
  customerId: string;
  username: string;
};

type UpdateParams = {
  customerId: string;
  username: string;
  size: string;
  mosaicData: {
    location: string;
    camera: string;
    owner: string;
  }[];
};

type UseMosaicAPIType = {
  getByUsername: (param: GetByUsernameParams) => Promise<Mosaic>;
  update: (data: UpdateParams) => Promise<Success>;
};

const useMosaicAPI = (): UseMosaicAPIType => {
  const API = useAxios();

  const getByUsername = async ({
    customerId,
    username
  }: GetByUsernameParams): Promise<Mosaic> => {
    const response: AxiosResponse<Mosaic> = await API.instance.get(
      APIEndpoints.MOSAIC,
      { params: { ["customer_id"]: customerId, username } }
    );
    return response.data;
  };

  const update = async (data: UpdateParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.MOSAIC_UPDATE,
      {
        ["customer_id"]: data.customerId,
        username: data.username,
        size: data.size,
        ["mosaic_data"]: data.mosaicData.map(item => ({
          location: item.location,
          camera: item.camera,
          owner: item.owner
        }))
      }
    );
    return response.data;
  };

  return {
    getByUsername,
    update
  };
};

export default useMosaicAPI;
