import RegexPatterns from "utils/RegexPatterns";
import { monitoringVehiclesColors } from "styles/theme";

export const EMPTY_VALUE = "－";

export const coalesce = (str?: string, emptyValue = "‒"): string =>
  str || emptyValue;

export const includes = (value: string, search: string): boolean =>
  value.trim().toLowerCase().includes(search.trim().toLowerCase());

export const isUrl = (url: string): boolean => RegexPatterns.URL.test(url);

export const setRestrictionLabelColor = (type: string): string => {
  switch (type) {
    case "speeding":
      return "rgba(2, 114, 226, 0.65)";
    case "blocked_road":
      return "rgba(211, 47, 47, 0.65)";
    case "vehicle_rotation":
      return "rgba(0, 0, 0, 0.65)";
    case "border_division":
      return "rgba(107, 109, 112, 0.65)";
    default:
      return "rgba(255, 152, 0, 0.65)";
  }
};

export const setMonitoringVehiclesColor = (type: string): string => {
  switch (type) {
    case "search_and_seizure":
      return monitoringVehiclesColors.seizure;
    case "automatic_monitoring":
      return monitoringVehiclesColors.rule;
    case "documentation":
      return monitoringVehiclesColors.documentation;
    case "theft_and_robbery":
      return monitoringVehiclesColors.steal;
    case "intelligence":
      return monitoringVehiclesColors.intelligence;
    default:
      return monitoringVehiclesColors.total;
  }
};

export const compareCaseInsensitive = (str1: string, str2: string): boolean => {
  const cleanedStr1 = str1.toLowerCase().replaceAll("_", "");
  const cleanedStr2 = str2.toLowerCase().replaceAll("_", "");
  return cleanedStr1 === cleanedStr2;
};
