import { FC, MouseEventHandler } from "react";
import { Icon, styled } from "@mui/material";
import { Circle, MapPin, Smartphone, Video } from "react-feather";
import Car from "icons/Car";
import { secondaryDefaultColors } from "styles/theme";
import { MapGeolocation } from "./Map";

type Props = {
  color?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  style?: MapGeolocation["style"];
};

const equipmentElement = ({ className }: { className?: string }) => (
  <div className={className}>
    <Icon component={Video} size={36} />
  </div>
);

const MapMarker: FC<Props> = ({
  color = "#26abfc",
  onClick,
  style = "pin"
}) => {
  let Pin;

  switch (style) {
    case "equipment":
      Pin = styled(equipmentElement)(() => ({
        backgroundColor: color,
        color: "#fff",
        width: "40px",
        height: "40px",
        position: "absolute",
        transform: "translate(-50%, calc(-100% - 7px))",
        cursor: "pointer",
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:after": {
          content: "''",
          position: "absolute",
          bottom: "-7px",
          width: "14px",
          height: "7px",
          backgroundColor: color,
          clipPath: "polygon(0 0, 50% 100%, 100% 0)"
        },
        onClick
      }));
      break;
    case "car":
      Pin = styled(Car)(() => ({
        position: "absolute",
        size: 36,
        color,
        onClick
      }));
      break;
    case "video":
      Pin = styled(Video)(() => ({
        position: "absolute",
        size: 36,
        color,
        onClick
      }));
      break;
    case "mobile":
      Pin = styled(Smartphone)(() => ({
        position: "absolute",
        size: 36,
        color,
        onClick
      }));
      break;
    case "circle":
      Pin = styled(Circle)(() => ({
        position: "absolute",
        width: 18,
        height: 18,
        fill: secondaryDefaultColors.main,
        color: secondaryDefaultColors.main
      }));
      break;
    case "pin":
    default:
      Pin = styled(MapPin)(() => ({
        position: "absolute",
        width: 32,
        height: 32,
        transform: "translate(-50%, -100%)",
        cursor: "pointer",
        "& circle": {
          fill: "white"
        },
        fill: color
      }));
      break;
  }

  return (
    <div onClick={onClick}>
      {style === "equipment" ? <Pin>{equipmentElement}</Pin> : <Pin />}
    </div>
  );
};

export default MapMarker;
