import {
  Dispatch,
  FC,
  ReactElement,
  ReactNode,
  SetStateAction,
  useEffect,
  useState
} from "react";
import {
  Box,
  Collapse,
  IconButton,
  Skeleton,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { ChevronDown, ChevronUp } from "react-feather";
import { Table, TableContainer } from "components/Table";
import Watermark from "components/Watermark";
import Order from "enums/OrderType";
import Paginator from "./Paginator";
import { useTranslation } from "react-i18next";
import { orderBy } from "lodash";
import { useIsMount } from "hooks/useIsMount";

const SKELETON_ROWS = 5;
const CELL_LINE_HEIGHT = "1.55rem";

const skeletonMarginsByAlignment = {
  left: "0",
  center: "0 auto",
  right: "0 0 0 auto"
};

type Header = {
  key: string;
  label?: ReactNode;
  noSort?: boolean;
  align?: "left" | "right" | "center";
};

type RowProps = {
  data: ReactNode[];
  hideColumns?: number[];
  headers?: Header[];
};

const Row: FC<RowProps> = ({ data, headers = [], hideColumns = [] }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  return (
    <>
      <TableRow>
        {hideColumns.length > 0 && (
          <TableCell sx={{ lineHeight: CELL_LINE_HEIGHT }} width="50px">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <ChevronUp /> : <ChevronDown />}
            </IconButton>
          </TableCell>
        )}
        {data.filter((_cell, idx) => !hideColumns.includes(idx))}
      </TableRow>
      {hideColumns.length > 0 && (
        <TableRow>
          <TableCell
            style={{
              lineHeight: CELL_LINE_HEIGHT,
              paddingBottom: 0,
              paddingTop: 0,
              backgroundColor: theme.palette.background.paper
            }}
            colSpan={99}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Table size="small">
                <TableBody>
                  {headers
                    .map((header, idx) => (
                      <TableRow key={`collapsed_row_${idx}`}>
                        <TableCell sx={{ lineHeight: CELL_LINE_HEIGHT }}>
                          {header.label}
                        </TableCell>
                        {data[idx]}
                      </TableRow>
                    ))
                    .filter((_h, idx) => hideColumns.includes(idx))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

type DataTableProps<D> = {
  // Headers & Sorting
  headers: Header[];
  defaultSort: [string, Order];
  onHeaderSort?: ((newData: D[]) => void) | Dispatch<SetStateAction<D[]>>;
  //
  // Rows & Columns
  data: D[];
  renderRow: (row: D) => ReactNode[];
  hideColumnsSm?: number[];
  hideColumnsXs?: number[];
  //
  // Pagination
  page?: number;
  totalPages?: number;
  pageSize?: number;
  totalItems?: number;
  onPageChange?: (page: number) => void;
  onPageSizeChange?: (itemsPerPage: number) => void;
  // Other
  isLoading?: boolean;
  watermarked?: boolean;
};

const DataTable = <D extends Record<string, unknown>>({
  // Headers & Sorting
  headers,
  defaultSort,
  onHeaderSort,
  //
  // Rows & Columns
  data,
  renderRow,
  hideColumnsSm = [],
  hideColumnsXs = [],
  //
  // Pagination
  pageSize = 10,
  onPageSizeChange,
  page = 1,
  onPageChange,
  totalPages = 1,
  totalItems = 0,
  //
  //Other
  isLoading = false,
  watermarked = false
}: DataTableProps<D>): ReactElement => {
  const isMount = useIsMount();
  const { t, i18n } = useTranslation();
  const [currentSort, setCurrentSort] = useState<[string, Order]>([
    defaultSort?.[0] ?? headers[0]?.key ?? "",
    defaultSort?.[1] ?? "asc"
  ]);
  const [noItems, setNoItems] = useState(t("pagination.noItemsInitial"));

  const handleSort = (prop: string) => {
    let newOrder: Order = "asc";

    if (currentSort[0] === prop && currentSort[1] === "asc") {
      newOrder = "desc";
    }

    const sortedData = orderBy(data, ["", prop], [false, newOrder]);

    setCurrentSort([prop, newOrder]);

    if (onHeaderSort) onHeaderSort(sortedData);
  };

  const [hideColumns, setHideColumns] = useState<number[]>([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.only("sm"));

  useEffect(() => {
    let cols: number[] = [];
    if (isMobile) {
      cols = hideColumnsXs;
    } else if (isTablet) {
      cols = hideColumnsSm;
    }
    setHideColumns(cols);
  }, [isMobile, isTablet]);

  useEffect(() => {
    if (!isLoading && !isMount) {
      setNoItems(t("pagination.noItems"));
    }
  }, [isLoading]);

  const high = Math.min(pageSize * page, totalItems);
  const low = high - data.length + 1;

  return (
    <>
      <Watermark enabled={watermarked && !isLoading}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {hideColumns.length > 0 && <TableCell />}
                {headers
                  .filter((_header, index) => !hideColumns.includes(index))
                  .map((header, index) => (
                    <TableCell
                      key={`header_${index}`}
                      sortDirection={
                        !header.noSort && header.key === currentSort[0]
                          ? currentSort[1]
                          : false
                      }
                      sx={{ lineHeight: CELL_LINE_HEIGHT }}
                      align={header.align ?? "left"}
                    >
                      <TableSortLabel
                        active={!header.noSort && header.key === currentSort[0]}
                        direction={
                          !header.noSort && header.key === currentSort[0]
                            ? currentSort[1]
                            : "asc"
                        }
                        onClick={() => {
                          if (!header.noSort) handleSort(header.key);
                        }}
                        hideSortIcon={header.noSort}
                        disabled={isLoading}
                      >
                        <div>{header.label}</div>
                        {!header.noSort && header.key === currentSort[0] ? (
                          <Box component="span" sx={visuallyHidden}>
                            {currentSort[1] === "asc"
                              ? "sorted ascending"
                              : "sorted descending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading &&
                Array.from(Array(SKELETON_ROWS).keys()).map(v => (
                  <TableRow key={`row_skeleton_${v}`}>
                    {headers
                      .filter((_header, index) => !hideColumns.includes(index))
                      .map(header => (
                        <TableCell
                          key={`row_skeleton_${v}_h_${header.key}`}
                          component="td"
                          scope="row"
                          sx={{ lineHeight: CELL_LINE_HEIGHT }}
                          align={header.align ?? "left"}
                        >
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width={60}
                            sx={{
                              margin:
                                skeletonMarginsByAlignment[
                                  header.align ?? "left"
                                ]
                            }}
                          />
                        </TableCell>
                      ))}
                  </TableRow>
                ))}
              {!isLoading && data.length < 1 && <TableRow></TableRow>}
              {!isLoading &&
                data.length > 0 &&
                data.map(renderRow).map((row, rowIndex) => (
                  <Row
                    key={`row_${rowIndex}`}
                    data={row.map((cell, cellIndex) => (
                      <TableCell
                        key={`row_${rowIndex}_cell_${cellIndex}`}
                        component="td"
                        scope="row"
                        sx={{ lineHeight: CELL_LINE_HEIGHT, padding: "8px" }}
                        align={headers[cellIndex].align ?? "left"}
                      >
                        {cell}
                      </TableCell>
                    ))}
                    hideColumns={hideColumns}
                    headers={headers}
                  />
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell
                  key="row_total_0"
                  component="td"
                  scope="row"
                  colSpan={headers.length}
                  align="center"
                  sx={{
                    lineHeight: CELL_LINE_HEIGHT,
                    padding: "0.3rem",
                    fontWeight: 500,
                    fontSize: "0.8rem"
                  }}
                >
                  {isLoading
                    ? t("waitState.loading")
                    : totalItems > 0
                    ? t("pagination.displayingOfTotalItems", {
                        size: data.length,
                        totalItems: totalItems.toLocaleString(i18n.language),
                        low: low.toLocaleString(i18n.language),
                        high: high.toLocaleString(i18n.language)
                      })
                    : noItems}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Watermark>
      <Paginator
        disabled={isLoading}
        initialPage={page}
        totalPages={totalPages}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
    </>
  );
};

export default DataTable;
