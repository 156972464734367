import { FC, useMemo, useState, useEffect, useCallback } from "react";
import {
  Box,
  Chip,
  Grid,
  styled,
  MenuItem,
  Checkbox,
  TextField,
  Typography,
  Autocomplete,
  OutlinedInput,
  CircularProgress,
  FormControlLabel,
  SelectChangeEvent,
  Divider
} from "@mui/material";
import useMonitoringAPI, {
  SchedulerDate,
  MonitoringType,
  VehiclesMake,
  dowHoursFull,
  dowHoursEmpty
} from "api/MonitoringAPI";
import useIntegrationsAPI, {
  TelegramCustomerIntegrationGroup
} from "api/IntegrationsAPI";
import { fromDateToUtc, fromUtcToDate } from "utils/DateFunctions";
import { AxiosError } from "axios";
import { orderBy } from "lodash";
import debounce from "lodash/debounce";
import TabPanel from "components/TabPanel";
import { Error } from "components/FormField";
import { useParams } from "react-router-dom";
import FormLabel from "components/FormLabel";
import useUserAPI, { User } from "api/UserAPI";
import { useTranslation } from "react-i18next";
import InputField from "components/InputField";
import { useAuth } from "contexts/AuthContext";
import FormDialog from "components/FormDialog";
import useUserGroupAPI from "api/UserGroupAPI";
import RegexPatterns from "utils/RegexPatterns";
import ColorPicker from "components/ColorPicker";
import SelectField from "components/SelectField";
import { useLocale } from "contexts/LocaleContext";
import { Controller, useForm } from "react-hook-form";
import DatePickerField from "components/DatePickerField";
import alert1Mp3 from "../../../assets/alerts/alert1.mp3";
import alert2Mp3 from "../../../assets/alerts/alert2.mp3";
import alarm1Mp3 from "../../../assets/alerts/alarm1.mp3";
import alarm2Mp3 from "../../../assets/alerts/alarm2.mp3";
import { MonitoringClone } from "./MonitoringVehiclesPage";
import snackNotification from "components/SnackNotification";
import SimpleSoundPlayer from "components/SimpleSoundPlayer";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import carAlertMp3 from "../../../assets/alerts/car_alert.mp3";
import truckAlertMp3 from "../../../assets/alerts/truck_alert.mp3";
import useToolsAPI from "api/ToolsAPI";
import { AutocompleteOptions } from "pages/MonitoredVehiclesDashboard/MonitoredVehiclesDashboardFilter";
import { VehicleColor, vehicleColors } from "utils/VehicleConsts";
import SchedulerForm from "components/SchedulerForm/SchedulerForm";

const alerts = [
  {
    name: "Alerta 1",
    value: "alert1",
    pathMp3: alert1Mp3
  },
  {
    name: "Alerta 2",
    value: "alert2",
    pathMp3: alert2Mp3
  },
  {
    name: "Alarme 1",
    value: "alarm1",
    pathMp3: alarm1Mp3
  },
  {
    name: "Alarme 2",
    value: "alarm2",
    pathMp3: alarm2Mp3
  },
  {
    name: "Alerta Carro",
    value: "car_alert",
    pathMp3: carAlertMp3
  },
  {
    name: "Alerta Caminhão",
    value: "truck_alert",
    pathMp3: truckAlertMp3
  }
];

const StyledDivCheckbox = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  div: {
    marginRight: theme.spacing(0.7)
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "start"
  }
}));

type MonitoringVehicleForm = {
  name: string;
  plate: string;
  make: string;
  retentionPopup: boolean;
  notificationSound: string;
  notificationColor: string;
  model: string;
  color: string;
  code: string;
  plateQtd: number;
  type: MonitoringType;
  characteristics: string;
  description: string;
  equipments: string[];
  owners: string[];
  ownersGroup: string[];
  startDate: Date | string | null;
  endDate: Date | string | null;
  soundAlert: boolean;
  popupAlert: boolean;
  email: boolean;
  whatsapp: boolean;
  telegram: boolean;
  sms: boolean;
};

const defaultValues: MonitoringVehicleForm = {
  name: "",
  plate: "",
  code: "",
  make: "",
  model: "",
  color: "",
  notificationSound: "alert1",
  notificationColor: "#323e54",
  plateQtd: 0,
  type: "documentation",
  characteristics: "",
  description: "",
  equipments: [],
  owners: [],
  ownersGroup: [],
  startDate: null,
  endDate: null,
  retentionPopup: false,
  soundAlert: false,
  popupAlert: false,
  email: false,
  whatsapp: false,
  telegram: false,
  sms: false
};

type Props = {
  monitoredIndex: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  onRequestFinish: () => void;
};

const MonitoringVehiclesFormDialog: FC<Props> = ({
  monitoredIndex,
  open,
  setOpen,
  onRequestFinish
}) => {
  const ToolsAPI = useToolsAPI();
  const UserGroupAPI = useUserGroupAPI();
  const MonitoringAPI = useMonitoringAPI();
  const IntegrationsAPI = useIntegrationsAPI();
  const { t } = useTranslation();
  const [reasonOptions, setReasonsOptions] = useState<AutocompleteOptions[]>([
    {
      value: "search_and_seizure",
      label: t("MonitoredVehiclesDashboardPage.search_and_seizure")
    },
    {
      value: "intelligence",
      label: t("MonitoredVehiclesDashboardPage.intelligence")
    },
    {
      value: "theft_and_robbery",
      label: t("MonitoredVehiclesDashboardPage.theft_and_robbery")
    },
    {
      value: "documentation",
      label: t("MonitoredVehiclesDashboardPage.documentation")
    },
    {
      value: "automatic_monitoring",
      label: t("MonitoredVehiclesDashboardPage.automatic_monitoring")
    }
  ]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(false);
  const isCreating = monitoredIndex === "";
  const { sessionUser } = useAuth();
  const { language } = useLocale();
  const [selectedSound, setSelectedSound] = useState<string>(alerts[0].pathMp3);
  const [selectedEquipments, setSelectedEquipments] = useState<string[]>([]);
  const [users, setUsers] = useState<string[]>([]);
  const [notCompletePlate, setNotCompletePlate] = useState<boolean>(false);
  const [userGroups, setUserGroups] = useState<string[]>([]);
  const [selectedOwners, setSelectedOwners] = useState<string[]>([]);
  const [selectedUserGroups, setSelectedUserGroups] = useState<string[]>([]);
  const [searchUsers, setSearchUsers] = useState("");
  const UserAPI = useUserAPI();
  const [schedulerDate, setSchedulerDate] = useState<SchedulerDate[]>([
    ...dowHoursEmpty
  ]);
  const [isModelLoading, setModelLoading] = useState<boolean>(false);
  const [colorSelected, setColorSelected] = useState<VehicleColor>({
    color: ""
  });
  const [makes, setMakes] = useState<VehiclesMake[]>([]);
  const [makeSelected, setMakeSelected] = useState<VehiclesMake>({
    name: "",
    isDivider: false
  });
  const [models, setModels] = useState<string[]>([]);
  const [modelSelected, setModelSelected] = useState<string>("");
  const days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday"
  ];
  const [selectedTelegramGroups, setSelectedTelegramGroups] = useState<
    string[]
  >([]);
  const [telegramGroups, setTelegramGroups] = useState<
    TelegramCustomerIntegrationGroup[]
  >([]);
  const [selectedVehicleClass, setSelectedVehicleClass] = useState<
    AutocompleteOptions[]
  >([]);
  const [classOptions, setClassOptions] = useState<AutocompleteOptions[]>([
    {
      value: "truck",
      label: t("MonitoredVehiclesDashboardPage.truck")
    },
    {
      value: "car",
      label: t("MonitoredVehiclesDashboardPage.car")
    },
    {
      value: "unknown",
      label: t("MonitoredVehiclesDashboardPage.unknown")
    },
    {
      value: "motorcycle",
      label: t("MonitoredVehiclesDashboardPage.motorcycle")
    },
    {
      value: "bus",
      label: t("MonitoredVehiclesDashboardPage.bus")
    }
  ]);

  const [selectedMonitoredReason, setSelectedMonitoredReason] =
    useState<AutocompleteOptions>({
      value: "",
      label: ""
    });

  const [monitoringVehicle, setMonitoringVehicle] =
    useState<MonitoringVehicleForm>(defaultValues);
  const { errorHandler } = useErrorHandler();

  const [notificationColor, setNotificationColor] = useState<string>(
    defaultValues.notificationColor
  );

  const { reset, watch, control, setValue, formState, handleSubmit } =
    useForm<MonitoringVehicleForm>({
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues
    });

  const params = useParams<MonitoringClone>();

  const monitoringClone: MonitoringClone = {
    plate: params.plate ? decodeURIComponent(params.plate) : "",
    make: params.make ? decodeURIComponent(params.make) : "",
    model: params.model ? decodeURIComponent(params.model) : "",
    color: params.color ? decodeURIComponent(params.color) : "",
    class: params.class ? decodeURIComponent(params.class) : ""
  };

  const watchPlate = watch("plate");
  const watchSound = watch("notificationSound");

  const watchTelegram = watch("telegram", false);

  useEffect(() => {
    const alert = alerts.find(s => s.value === watchSound);
    if (alert) {
      setSelectedSound(alert.pathMp3);
    }
  }, [watchSound]);

  useEffect(() => {
    if (!watchTelegram) {
      setSelectedTelegramGroups([]);
    }
  }, [watchTelegram]);

  useEffect(() => {
    if (open && isCreating) {
      setSchedulerDate([...dowHoursFull]);
    }
  }, [open]);

  useEffect(() => {
    if (makeSelected.name === "") {
      setModelSelected("");
    } else {
      setModelSelected("");
      setModelOptions();
    }
  }, [makeSelected]);

  const updateAutoCompleteLabel = (options: AutocompleteOptions) => ({
    ...options,
    label: t(`MonitoredVehiclesDashboardPage.${options.value}`)
  });

  const changeClassOptionsValue = (objects: AutocompleteOptions[]) => {
    setSelectedVehicleClass(objects);
  };

  useEffect(() => {
    if (open) {
      const newReasons: AutocompleteOptions[] = reasonOptions.map(
        updateAutoCompleteLabel
      );
      const newClasses: AutocompleteOptions[] = classOptions.map(
        updateAutoCompleteLabel
      );
      setClassOptions([...newClasses]);
      setReasonsOptions([...newReasons]);
    }
  }, [language, open]);

  useEffect(() => {
    if (watchPlate) {
      if (watchPlate.length <= 7) {
        setValue("plate", watchPlate.toUpperCase());
      } else {
        setValue("plate", watchPlate.substring(0, 7));
      }
    }
  }, [watchPlate]);

  const requestData = useCallback(async () => {
    if (!sessionUser) return;
    setIsLoading(true);
    try {
      const [
        userGroupResponse,
        telegramGroupsResponse,
        monitoringResponse,
        makeResponse
      ] = await Promise.all([
        UserGroupAPI.listAll({
          customerId: sessionUser.customer_id,
          page: -1
        }),
        IntegrationsAPI.listTelegramCustomerIntegration(
          sessionUser.customer_id
        ),
        isCreating
          ? Promise.resolve(null)
          : await MonitoringAPI.getByIndex({
              customerId: sessionUser.customer_id,
              index: monitoredIndex
            }),
        ToolsAPI.listAllMakes()
      ]);

      const liteMake = makeResponse.make
        .filter(item => item.lite === true)
        .map(item => ({ name: item.name, isDivider: false }));
      liteMake[liteMake.length - 1].isDivider = true;
      const nonLiteMake = makeResponse.make
        .filter(item => item.lite === false)
        .map(item => ({ name: item.name, isDivider: false }));
      const makes = liteMake.concat(nonLiteMake);

      setMakes(makes || []);
      setUserGroups(
        (Array.isArray(userGroupResponse.data)
          ? userGroupResponse.data
          : []
        ).map(userGroup => userGroup["group_name"])
      );
      setTelegramGroups(telegramGroupsResponse.data);

      if (monitoringResponse && !isCreating) {
        setMonitoringVehicle({
          name: monitoringResponse.name,
          plate: monitoringResponse.plate,
          notificationSound: monitoringResponse["custom_sound_alert"] || "",
          notificationColor:
            monitoringResponse["alert_color"] ||
            defaultValues.notificationColor,
          retentionPopup: monitoringResponse["persist_alert"] || false,
          make: monitoringResponse["monitoring_data"].vehicle_make || "",
          model: monitoringResponse["monitoring_data"].vehicle_model || "",
          color: monitoringResponse["monitoring_data"].vehicle_color || "",
          code: monitoringResponse.code,
          type: monitoringResponse.type,
          plateQtd: monitoringResponse["monitoring_data"].diff_chars_in_plate,
          characteristics:
            monitoringResponse["monitoring_data"].characteristics,
          description: monitoringResponse["monitoring_data"].description,
          equipments: [],
          owners: monitoringResponse.owners,
          ownersGroup: monitoringResponse["owners_group"],
          startDate: fromUtcToDate(monitoringResponse["initial_date"]),
          endDate: fromUtcToDate(monitoringResponse["final_date"]),
          soundAlert: monitoringResponse["sound_alert"],
          popupAlert: monitoringResponse["popup_alert"],
          email: monitoringResponse["monitoring_data"].notifications.email,
          whatsapp:
            monitoringResponse["monitoring_data"].notifications.whatsapp,
          telegram:
            monitoringResponse["monitoring_data"].notifications.telegram,
          sms: monitoringResponse["monitoring_data"].notifications.sms
        });
        setNotificationColor(
          monitoringResponse["alert_color"] || defaultValues.notificationColor
        );
        setSelectedEquipments(monitoringResponse.locations);
        setSelectedOwners(monitoringResponse.owners);
        setSelectedUserGroups(monitoringResponse["owners_group"] || []);
        setSelectedTelegramGroups(
          monitoringResponse.monitoring_data.notifications.telegram_groups || []
        );
        setSelectedMonitoredReason({
          value: monitoringResponse.type,
          label: t(`MonitoredVehiclesDashboardPage.${monitoringResponse.type}`)
        });

        setMakeSelected({
          name: monitoringResponse["monitoring_data"].vehicle_make || "",
          isDivider: false
        });
        setModelSelected(monitoringResponse["monitoring_data"].vehicle_model);
        setColorSelected({
          color: monitoringResponse["monitoring_data"].vehicle_color || ""
        });

        if (
          (monitoringResponse["monitoring_data"].vehicle_classes &&
            monitoringResponse["monitoring_data"].vehicle_classes.length > 0) ||
          monitoringResponse["monitoring_data"].vehicle_make ||
          monitoringResponse["monitoring_data"].vehicle_color ||
          monitoringResponse["monitoring_data"].vehicle_model
        ) {
          setNotCompletePlate(true);
        }

        if (
          monitoringResponse["monitoring_data"].vehicle_classes &&
          monitoringResponse["monitoring_data"].vehicle_classes.length > 0
        ) {
          const vehicleClasses: AutocompleteOptions[] = [];

          monitoringResponse["monitoring_data"].vehicle_classes.map(item =>
            vehicleClasses.push({
              value: item,
              label: t(`MonitoredVehiclesDashboardPage.${item}`)
            })
          );
          setSelectedVehicleClass(vehicleClasses);
        }

        if (
          monitoringResponse.monitoring_data.dow_hours &&
          monitoringResponse.monitoring_data.dow_hours.length > 0 &&
          monitoringResponse.monitoring_data.dow_hours.find(
            scheduler => scheduler.hours_intervals.length > 0
          )
        ) {
          const refreshSchedulerDate: {
            dow: string;
            hours_intervals: { start_hour: string; end_hour: string }[];
          }[] = [...dowHoursEmpty];

          monitoringResponse.monitoring_data.dow_hours.forEach(scheduler => {
            const index = refreshSchedulerDate.findIndex(
              item => item.dow === scheduler.dow
            );
            if (index !== -1) {
              refreshSchedulerDate[index] = scheduler;
            }
          });
          setSchedulerDate([...refreshSchedulerDate]);
        } else if (isCreating) {
          setSchedulerDate([...dowHoursFull]);
        }
      }

      if (isCreating && monitoringClone.plate) {
        setValue("plate", monitoringClone.plate || "");
        setValue("make", monitoringClone.make || "");
        setValue("model", monitoringClone.model || "");
        setValue("color", monitoringClone.color || "");
        if (monitoringClone.class !== "") {
          setSelectedVehicleClass([
            {
              value: monitoringClone.class,
              label: t(
                `MonitoredVehiclesDashboardPage.${monitoringClone.class}`
              )
            }
          ]);
        }
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  }, [isCreating, reset, sessionUser, t, monitoredIndex]);

  useEffect(() => {
    if (open) {
      requestData();
    }
  }, [requestData, open]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "notificationColor" && value.notificationColor) {
        if (RegexPatterns.HEX_COLOR.test(value.notificationColor)) {
          setNotificationColor(value.notificationColor);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    reset(monitoringVehicle);
  }, [setValue, monitoringVehicle]);

  useEffect(() => {
    if (!open) {
      setMonitoringVehicle(defaultValues);
      reset(defaultValues);
      setSelectedEquipments([]);
      setSelectedOwners([]);
      setUsers([]);
      setUserGroups([]);
      setSelectedUserGroups([]);
      setSelectedTelegramGroups([]);
      setSelectedMonitoredReason({
        value: "",
        label: ""
      });
      setSelectedVehicleClass([]);
      setNotCompletePlate(false);
      setNotificationColor(defaultValues.notificationColor);
      setSchedulerDate([...dowHoursEmpty]);
      setMakeSelected({ name: "", isDivider: false });
      setModelSelected("");
      setColorSelected({ color: "" });
    }
  }, [open]);

  const setModelOptions = async () => {
    if (!sessionUser?.["customer_id"]) return;
    setModelLoading(true);
    try {
      const response = await ToolsAPI.getGenericModels(makeSelected.name);
      setModels(response.model.map(item => item.name) || []);
    } catch (error: unknown) {
      const err = error as AxiosError;
      if (err.response?.data.error.code === 4054) {
        setModels([]);
      } else {
        errorHandler({ error });
      }
    } finally {
      setModelLoading(false);
    }
  };

  const onSubmit = async (data: MonitoringVehicleForm) => {
    if (!sessionUser?.["customer_id"]) return;
    setIsLoading(true);
    const newData = { ...data };

    const newScheduler: SchedulerDate[] = [];
    schedulerDate.forEach(scheduler => {
      if (scheduler.hours_intervals.length > 0) {
        newScheduler.push(scheduler);
      }
    });

    try {
      newData.type = selectedMonitoredReason.value as MonitoringType;
      newData.equipments = selectedEquipments;
      newData.owners = selectedOwners;
      newData.ownersGroup = selectedUserGroups;
      newData.startDate = newData.startDate
        ? fromDateToUtc(newData.startDate as Date)
        : "";
      newData.endDate = newData.endDate
        ? fromDateToUtc(newData.endDate as Date)
        : "";
      newData.make = makeSelected.name;
      newData.model = modelSelected;
      newData.color = colorSelected.color;

      let vehicleClasses: string[] = [];
      if (selectedVehicleClass.length > 0) {
        vehicleClasses = selectedVehicleClass.map(item => item.value);
      }

      if (isCreating) {
        await MonitoringAPI.create({
          name: newData.name,
          plate: newData.plate,
          ["vehicle_make"]: newData.make,
          plateQtd: newData.plateQtd,
          ["custom_sound_alert"]: newData.notificationSound,
          ["alert_color"]: newData.notificationColor,
          ["persist_alert"]: newData.retentionPopup,
          ["vehicle_model"]: newData.model,
          ["vehicle_color"]: newData.color,
          ["vehicle_classes"]: vehicleClasses,
          code: newData.code,
          startDate: newData.startDate,
          endDate: newData.endDate,
          soundAlert: newData.soundAlert,
          popupAlert: newData.popupAlert,
          equipments: newData.equipments,
          owners: newData.owners,
          ownersGroup: newData.ownersGroup,
          description: newData.description,
          type: newData.type,
          characteristics: newData.characteristics,
          email: newData.email,
          whatsapp: newData.whatsapp,
          telegram: newData.telegram,
          telegramGroups: selectedTelegramGroups,
          sms: newData.sms,
          ["dow_hours"]: newScheduler,
          customerId: sessionUser["customer_id"]
        });
        snackNotification.success(
          t("MonitoringVehicles.monitoredVehicleCreated")
        );
      } else {
        await MonitoringAPI.update({
          name: newData.name,
          plate: newData.plate,
          ["vehicle_make"]: newData.make,
          ["vehicle_model"]: newData.model,
          ["vehicle_color"]: newData.color,
          ["vehicle_classes"]: vehicleClasses,
          ["custom_sound_alert"]: newData.notificationSound,
          ["alert_color"]: newData.notificationColor,
          ["persist_alert"]: newData.retentionPopup,
          plateQtd: newData.plateQtd,
          code: newData.code,
          startDate: newData.startDate,
          endDate: newData.endDate,
          soundAlert: newData.soundAlert,
          popupAlert: newData.popupAlert,
          equipments: newData.equipments,
          owners: newData.owners,
          ownersGroup: newData.ownersGroup,
          description: newData.description,
          type: newData.type,
          characteristics: newData.characteristics,
          email: newData.email,
          whatsapp: newData.whatsapp,
          telegram: newData.telegram,
          telegramGroups: selectedTelegramGroups,
          sms: newData.sms,
          index: monitoredIndex,
          ["dow_hours"]: newScheduler,
          customerId: sessionUser["customer_id"]
        });
        snackNotification.success(
          t("MonitoringVehicles.monitoredVehicleUpdated")
        );
      }
      onRequestFinish();
      closeDialog();
    } catch (error) {
      errorHandler({ error });
    } finally {
      setSchedulerDate([...dowHoursEmpty]);
      setIsLoading(false);
    }
  };

  const closeDialog = () => {
    setOpen(false);
    setSelectedMonitoredReason({
      value: "",
      label: ""
    });
  };

  const isFormCompleted = (): boolean => {
    if (watchTelegram && selectedTelegramGroups.length === 0) return false;
    if (
      watchPlate.length === 0 ||
      (notCompletePlate && !verifyVehicleCharacteristics())
    )
      return false;
    return (
      formState.isValid &&
      !isLoading &&
      (selectedOwners.length > 0 || selectedUserGroups.length > 0)
    );
  };

  const changeReasonOptionsValue = (object: AutocompleteOptions | null) => {
    if (object) {
      setSelectedMonitoredReason(object);
    }
  };

  const verifyVehicleCharacteristics = (): boolean => {
    const filledFields = [
      selectedVehicleClass.length > 0 ? "OK" : "",
      modelSelected,
      makeSelected.name,
      colorSelected.color
    ].filter(campo => campo.trim() !== "").length;

    if (filledFields >= 2) {
      return true;
    }
    return false;
  };

  const requestSearchUsers = async (search: string) => {
    if (!sessionUser?.["customer_id"]) return;
    try {
      setIsLoadingUsers(true);
      if (search) {
        const userResponse = await UserAPI.search(
          sessionUser["customer_id"],
          search,
          0
        );
        const usersData = userResponse.data as User[];
        if (usersData) {
          setUsers(usersData.map(user => user.username));
        } else {
          setUsers([]);
        }
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoadingUsers(false);
    }
  };

  const fetchUsers = useMemo(
    () =>
      debounce((searchValue: string) => {
        requestSearchUsers(searchValue);
      }, 700),
    []
  );

  useEffect(() => {
    fetchUsers(searchUsers);
  }, [searchUsers, fetchUsers]);

  return (
    <FormDialog
      onConfirm={handleSubmit(onSubmit)}
      open={open}
      setOpen={setOpen}
      isLoading={isLoading}
      dirty={formState.isDirty}
      confirmDisabled={!formState.isValid || !isFormCompleted()}
      confirmText={t("action.apply")}
      title={
        isCreating
          ? t("MonitoringVehicles.createANewMonitoredVehicle")
          : t("MonitoringVehicles.editMonitoredVehicle")
      }
    >
      <TabPanel
        tabs={[
          {
            value: "general",
            label: t("MonitoringVehicles.labels.general"),
            content: (
              <Grid container rowSpacing={1} columnSpacing={2}>
                <Grid item xs={12} sm={8}>
                  <Controller
                    name="name"
                    control={control}
                    rules={{
                      required: t("form.requiredField").toString()
                    }}
                    render={({ field, fieldState }) => (
                      <InputField
                        label={t("MonitoringVehicles.monitoring")}
                        customProps={{
                          placeholder: t("MonitoringVehicles.monitoringHint"),
                          disabled: isLoading,
                          required: true
                        }}
                        field={{ ...field }}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormLabel required>
                    {t("MonitoringVehicles.type.label")}
                  </FormLabel>
                  <Autocomplete
                    openText={t("action.open")}
                    closeText={t("action.close")}
                    disableClearable
                    options={orderBy(
                      reasonOptions,
                      ["", "label"],
                      [false, "asc"]
                    )}
                    getOptionLabel={option => option.label}
                    filterSelectedOptions
                    onChange={(event, newValues) => {
                      event.preventDefault();
                      changeReasonOptionsValue(newValues);
                    }}
                    value={selectedMonitoredReason}
                    renderOption={(props, option) => (
                      <Typography key={option.value} {...props}>
                        {option.label}
                      </Typography>
                    )}
                    noOptionsText={t("form.noOptions")}
                    size="small"
                    renderInput={params => <TextField {...params} />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field, fieldState }) => (
                      <InputField
                        label={t("MonitoringVehicles.description")}
                        customProps={{
                          placeholder: t("MonitoringVehicles.descriptionHint"),
                          disabled: isLoading
                        }}
                        field={{ ...field }}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="startDate"
                    control={control}
                    rules={{
                      required: t("form.requiredField").toString()
                    }}
                    render={({ field, fieldState }) => (
                      <DatePickerField
                        required
                        gutter
                        label={t("MonitoringVehicles.activationDate")}
                        disabled={isLoading}
                        field={{ ...field }}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="endDate"
                    control={control}
                    rules={{
                      required: t("form.requiredField").toString()
                    }}
                    render={({ field, fieldState }) => (
                      <DatePickerField
                        required
                        gutter
                        label={t("MonitoringVehicles.expirationDateForm")}
                        disabled={isLoading}
                        field={{ ...field }}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )
          },
          {
            value: "vehicle",
            label: t("MonitoringVehicles.labels.vehicle"),
            content: (
              <Grid container rowSpacing={1} columnSpacing={2}>
                <Grid item xs={8}>
                  <Controller
                    name="plate"
                    control={control}
                    rules={{
                      required: t("form.requiredField").toString(),
                      maxLength: {
                        value: 7,
                        message: t("form.maxLength", {
                          length: 7
                        }).toString()
                      }
                    }}
                    render={({ field, fieldState }) => (
                      <InputField
                        label={t("MonitoringVehicles.plate")}
                        customProps={{
                          placeholder: t("MonitoringVehicles.plateHint"),
                          disabled: isLoading,
                          required: true
                        }}
                        gutter={false}
                        field={{ ...field }}
                        fieldState={fieldState}
                      />
                    )}
                  />
                  <Typography
                    variant="caption"
                    align="left"
                    color="primary.light"
                  >
                    {t("MonitoringVehicles.tooltipPlate")}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Box sx={{ mt: "24px", ml: "24px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          color="secondary"
                          checked={notCompletePlate}
                          onChange={(_event, newValue) => {
                            setNotCompletePlate(newValue);
                            setSelectedVehicleClass([]);
                            setValue("model", "");
                            setValue("make", "");
                            setValue("color", "");
                          }}
                        />
                      }
                      label={t("MonitoringVehicles.emptyPlate").toString()}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4" align="left">
                    {t("MonitoringVehicles.characteristics")}
                  </Typography>
                  <Typography
                    variant="caption"
                    align="left"
                    color={
                      notCompletePlate && !verifyVehicleCharacteristics()
                        ? "error"
                        : "primary.light"
                    }
                  >
                    {t("MonitoringVehicles.characteristicsHint")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormLabel
                    required={
                      notCompletePlate && !verifyVehicleCharacteristics()
                    }
                  >
                    {t("MonitoredVehiclesDashboardPage.vehicleClass")}
                  </FormLabel>
                  <Autocomplete
                    multiple
                    disabled={isLoading || !notCompletePlate}
                    filterSelectedOptions
                    openText={t("action.open")}
                    closeText={t("action.close")}
                    clearText={t("action.clear")}
                    options={orderBy(
                      classOptions,
                      ["", "label"],
                      [false, "asc"]
                    )}
                    getOptionLabel={option => option.label}
                    onChange={(event, newValues) => {
                      event.preventDefault();
                      changeClassOptionsValue(newValues);
                    }}
                    noOptionsText={t("form.noOptions")}
                    value={selectedVehicleClass}
                    size="small"
                    renderInput={params => (
                      <TextField
                        sx={{
                          border: `1px solid ${
                            notCompletePlate && !verifyVehicleCharacteristics()
                              ? "red"
                              : "#e0e0e0"
                          }`
                        }}
                        {...params}
                        placeholder={t(
                          "MonitoredVehiclesDashboardPage.vehicleClassHint"
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormLabel>{t("MonitoringVehicles.make")}</FormLabel>
                  <Autocomplete
                    disablePortal
                    size="small"
                    disabled={isLoading || !notCompletePlate}
                    openText={t("action.open")}
                    closeText={t("action.close")}
                    clearText={t("action.clear")}
                    options={makes}
                    noOptionsText={t("form.noOptions")}
                    getOptionLabel={option => option.name}
                    value={makeSelected}
                    onChange={(event, value) => {
                      event.preventDefault();
                      setMakeSelected({
                        name: value?.name || "",
                        isDivider: false
                      });
                    }}
                    renderInput={params => (
                      <div style={{ position: "relative" }}>
                        <TextField
                          {...params}
                          sx={{
                            border: `1px solid ${
                              notCompletePlate &&
                              !verifyVehicleCharacteristics()
                                ? "red"
                                : "#e0e0e0"
                            }`
                          }}
                          required={
                            notCompletePlate && !verifyVehicleCharacteristics()
                          }
                          placeholder={t("MonitoringVehicles.makeHint")}
                        />
                      </div>
                    )}
                    renderOption={(props, value) => (
                      <>
                        <li {...props}>{value.name}</li>
                        {value.isDivider === true ? <Divider /> : null}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormLabel>{t("MonitoringVehicles.model")}</FormLabel>
                  <Autocomplete
                    disablePortal
                    size="small"
                    openText={t("action.open")}
                    closeText={t("action.close")}
                    clearText={t("action.clear")}
                    options={models}
                    disabled={
                      isLoading ||
                      !notCompletePlate ||
                      makeSelected.name === "" ||
                      isModelLoading
                    }
                    noOptionsText={t("form.noOptions")}
                    getOptionLabel={option => option}
                    value={modelSelected}
                    onChange={(event, value) => {
                      event.preventDefault();
                      setModelSelected(value || "");
                    }}
                    renderInput={params => (
                      <div style={{ position: "relative" }}>
                        <TextField
                          {...params}
                          sx={{
                            border: `1px solid ${
                              notCompletePlate &&
                              !verifyVehicleCharacteristics()
                                ? "red"
                                : "#e0e0e0"
                            }`
                          }}
                          required={
                            notCompletePlate && !verifyVehicleCharacteristics()
                          }
                          placeholder={t("MonitoringVehicles.modelHint")}
                        />
                        {isModelLoading && (
                          <CircularProgress
                            color="secondary"
                            size={24}
                            sx={{
                              position: "absolute",
                              top: "10px",
                              right: "42px"
                            }}
                          />
                        )}
                      </div>
                    )}
                  />
                  <Typography
                    align="left"
                    variant="caption"
                    color="primary.light"
                  >
                    {t("DefaultPageLayout.tooltipModel")}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormLabel>{t("CaptureReportPage.color")}</FormLabel>
                  <Autocomplete
                    disablePortal
                    size="small"
                    openText={t("action.open")}
                    closeText={t("action.close")}
                    clearText={t("action.clear")}
                    disabled={isLoading || !notCompletePlate}
                    options={vehicleColors}
                    noOptionsText={t("form.noOptions")}
                    getOptionLabel={option => option.color}
                    value={colorSelected}
                    onChange={(event, value) => {
                      event.preventDefault();
                      setColorSelected({ color: value?.color || "" });
                    }}
                    renderInput={params => (
                      <div style={{ position: "relative" }}>
                        <TextField
                          {...params}
                          sx={{
                            border: `1px solid ${
                              notCompletePlate &&
                              !verifyVehicleCharacteristics()
                                ? "red"
                                : "#e0e0e0"
                            }`
                          }}
                          required={
                            notCompletePlate && !verifyVehicleCharacteristics()
                          }
                          placeholder={t("MonitoringVehicles.colorHint")}
                        />
                      </div>
                    )}
                  />
                </Grid>
              </Grid>
            )
          },
          {
            value: "notification",
            label: t("MonitoringVehicles.labels.notification"),
            content: (
              <Grid container rowSpacing={1} columnSpacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4" align="left">
                    {t("MonitoringVehicles.selectedContact")}
                  </Typography>
                  <StyledDivCheckbox>
                    {["email", "telegram"].map(contact => (
                      <div key={contact}>
                        <Controller
                          name={contact as keyof MonitoringVehicleForm}
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="secondary"
                                  onChange={field.onChange}
                                  disabled={isLoading}
                                  checked={Boolean(field.value)}
                                />
                              }
                              label={t(
                                `MonitoringVehicles.${contact}`
                              ).toString()}
                            />
                          )}
                        />
                      </div>
                    ))}
                  </StyledDivCheckbox>
                </Grid>
                {watchTelegram && (
                  <Grid item xs={12} sm={12}>
                    <SelectField
                      required
                      multiple
                      label={t("MonitoringVehicles.telegramsGroup")}
                      disabled={isLoading}
                      error={selectedTelegramGroups.length === 0}
                      input={
                        <OutlinedInput id="select-multiple-chip" label="Chip" />
                      }
                      value={selectedTelegramGroups}
                      onChange={({ target }: SelectChangeEvent<unknown>) => {
                        const val = target.value as string[];
                        setSelectedTelegramGroups(val);
                      }}
                      renderValue={param => {
                        const selected = param as string[];
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5
                            }}
                          >
                            {telegramGroups
                              .filter(g => selected.includes(g.index ?? ""))
                              .map(group => (
                                <Chip key={group.index} label={group.name} />
                              ))}
                          </Box>
                        );
                      }}
                    >
                      <MenuItem disabled value="">
                        {t("MonitoringVehicles.selectOneOrMoreGroups")}
                      </MenuItem>
                      {telegramGroups.map((group, key) => (
                        <MenuItem key={key} value={group.index}>
                          {group.name}
                        </MenuItem>
                      ))}
                    </SelectField>
                    {selectedTelegramGroups.length === 0 && (
                      <Error>
                        {t("MonitoringVehicles.selectOneOrMoreGroups")}
                      </Error>
                    )}
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography variant="h4" align="left">
                    {t("MonitoringVehicles.selectedAlert")}
                  </Typography>
                  <StyledDivCheckbox>
                    {["soundAlert", "popupAlert"].map(alertType => (
                      <div key={alertType}>
                        <Controller
                          name={alertType as keyof MonitoringVehicleForm}
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="secondary"
                                  onChange={field.onChange}
                                  disabled={isLoading}
                                  checked={Boolean(field.value)}
                                />
                              }
                              label={t(
                                `MonitoringVehicles.${alertType}`
                              ).toString()}
                            />
                          )}
                        />
                      </div>
                    ))}
                  </StyledDivCheckbox>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4" align="left">
                    {t("MonitoringVehicles.notificationPeople")}
                  </Typography>
                  <Typography
                    variant="caption"
                    align="left"
                    color={
                      selectedOwners.length === 0 &&
                      selectedUserGroups.length === 0
                        ? "error"
                        : "primary.light"
                    }
                  >
                    {t("MonitoringVehicles.notificationPeopleHint")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormLabel
                    required={
                      !selectedOwners.length && !selectedUserGroups.length
                    }
                  >
                    {t("MonitoringVehicles.responsibleGroup")}
                  </FormLabel>
                  <Autocomplete
                    sx={{
                      mb: 2
                    }}
                    multiple
                    filterSelectedOptions
                    openText={t("action.open")}
                    closeText={t("action.close")}
                    clearText={t("action.clear")}
                    options={userGroups}
                    getOptionLabel={option => option}
                    noOptionsText={t("form.noOptions")}
                    size="small"
                    value={selectedUserGroups}
                    onChange={(event, ug) => {
                      event.preventDefault();
                      setSelectedUserGroups(ug);
                    }}
                    renderInput={params => (
                      <div style={{ position: "relative" }}>
                        <TextField
                          sx={{
                            border: `1px solid ${
                              !selectedOwners.length &&
                              !selectedUserGroups.length
                                ? "red"
                                : "#e0e0e0"
                            }`
                          }}
                          {...params}
                          placeholder={t(
                            "MonitoringVehicles.responsibleGroupHint"
                          )}
                        />
                      </div>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormLabel
                    required={
                      !selectedOwners.length && !selectedUserGroups.length
                    }
                  >
                    {t("MonitoringVehicles.users")}
                  </FormLabel>
                  <Autocomplete
                    sx={{
                      mb: 2
                    }}
                    multiple
                    filterSelectedOptions
                    openText={t("action.open")}
                    closeText={t("action.close")}
                    clearText={t("action.clear")}
                    options={users}
                    getOptionLabel={option => option}
                    noOptionsText={t("form.noOptions")}
                    size="small"
                    value={selectedOwners}
                    onChange={(event, so) => {
                      event.preventDefault();
                      setSelectedOwners(so);
                    }}
                    renderInput={params => (
                      <div style={{ position: "relative" }}>
                        <TextField
                          sx={{
                            border: `1px solid ${
                              !selectedOwners.length &&
                              !selectedUserGroups.length
                                ? "red"
                                : "#e0e0e0"
                            }`
                          }}
                          {...params}
                          placeholder={t("form.startTyping")}
                          onChange={event => {
                            setSearchUsers(event.target.value);
                          }}
                        />
                        {isLoadingUsers && (
                          <CircularProgress
                            color="secondary"
                            size={24}
                            sx={{
                              position: "absolute",
                              top: "10px",
                              right: "42px"
                            }}
                          />
                        )}
                      </div>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4" align="left">
                    {t("MonitoringVehicles.alertCustom")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="notificationSound"
                    control={control}
                    render={({ field }) => (
                      <SelectField
                        {...field}
                        label={t("MonitoringVehicles.notificationSound")}
                        onChange={event => {
                          field.onChange(event.target.value as string);
                        }}
                        sx={{ mb: 2 }}
                      >
                        {alerts.map(alert => (
                          <MenuItem key={alert.value} value={alert.value}>
                            {alert.name}
                          </MenuItem>
                        ))}
                      </SelectField>
                    )}
                  />
                  <div>
                    <SimpleSoundPlayer sound={selectedSound} />
                  </div>
                </Grid>
                <Grid item sm={12} md={6}>
                  <Controller
                    name="notificationColor"
                    control={control}
                    render={({ field, fieldState }) => (
                      <InputField
                        label={t("MonitoringVehicles.notificationColor")}
                        customProps={{
                          startAdornment: (
                            <ColorPicker
                              color={notificationColor}
                              colorChanged={hex => {
                                setValue("notificationColor", hex);
                              }}
                            />
                          ),
                          defaultValue: notificationColor
                        }}
                        field={{ ...field }}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={12}>
                  <Controller
                    name="retentionPopup"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="secondary"
                            onChange={field.onChange}
                            disabled={isLoading}
                            checked={Boolean(field.value)}
                          />
                        }
                        label={t(
                          "MonitoringVehicles.retentionPopup"
                        ).toString()}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )
          },
          {
            value: "hours",
            label: t("MonitoringVehicles.labels.hours"),
            content: open && (
              <>
                <SchedulerForm
                  days={days}
                  schedulerDate={schedulerDate}
                  setSchedulerDate={setSchedulerDate}
                />
              </>
            )
          }
        ]}
      />
    </FormDialog>
  );
};

export default MonitoringVehiclesFormDialog;
