import { AxiosResponse } from "axios";
import APIEndpoints from "enums/APIEndpoints";
import { NestedData, Success } from "./API";
import useAxios from "hooks/useAxios";

export type Permission = {
  key: string;
  subMenu: {
    key: string;
    menu: string[];
    items?: string[];
  }[];
};

export type Profile = {
  profile: string;
  permissions: Permission[];
};

type ListAllParams = {
  customerId: string;
};

type GetByNameParams = {
  customerId: string;
  profileName: string;
};

type CreateParams = {
  customerId: string;
  data: Profile;
};

type UpdateParams = {
  customerId: string;
  data: Profile;
};

type DeleteByNameParams = {
  customerId: string;
  name: string;
};

type ProfileUsersParams = {
  customerId: string;
  profile: string;
  page: number;
};

type updateUserProfileParams = {
  customerId: string;
  profile: string;
  username: string;
};

export type PaginatedProfileUsers = {
  ["page"]: number;
  ["total_count"]: number;
  ["total_pages"]: number;
  ["users"]: string[];
};

const cleanupPermissions = (permissions: Permission[]): Permission[] =>
  permissions
    .map(permission => ({
      ...permission,
      subMenu: permission.subMenu
        .filter(submenu => submenu.menu.length > 0)
        .map(submenu => ({
          key: submenu.key,
          menu: submenu.menu
        }))
    }))
    .filter(permission => permission.subMenu.length > 0);

const useProfileAPI = (): {
  create: ({ customerId, data }: CreateParams) => Promise<Success>;
  update: ({ customerId, data }: UpdateParams) => Promise<Success>;
  listAll: ({ customerId }: ListAllParams) => Promise<NestedData<Profile[]>>;
  getByName: ({ customerId, profileName }: GetByNameParams) => Promise<Profile>;
  deleteByName: ({ customerId, name }: DeleteByNameParams) => Promise<Success>;
  getDefault: () => Promise<Permission[]>;
  listProfileUsers: (
    param: ProfileUsersParams
  ) => Promise<NestedData<PaginatedProfileUsers>>;
  updateUserProfile: (data: updateUserProfileParams) => Promise<Success>;
} => {
  const API = useAxios();

  const listAll = async ({
    customerId
  }: ListAllParams): Promise<NestedData<Profile[]>> => {
    const response: AxiosResponse<NestedData<Profile[]>> =
      await API.instance.post(APIEndpoints.PROFILE_LIST, {
        ["customer_id"]: customerId
      });
    return response.data;
  };

  const getByName = async ({
    customerId,
    profileName
  }: GetByNameParams): Promise<Profile> => {
    const response: AxiosResponse<Profile> = await API.instance.get(
      APIEndpoints.PROFILE,
      { params: { customer_id: customerId, profile: profileName } }
    );
    return response.data;
  };

  const getDefault = async (): Promise<Permission[]> => {
    const response: AxiosResponse<Profile> = await API.instance.get(
      APIEndpoints.PROFILE,
      { params: { customer_id: "DEFAULT", profile: "Administrador" } }
    );
    if (!response || !response.data) return [];
    return response.data.permissions.map(permission => ({
      ...permission,
      subMenu: permission.subMenu.map(submenu => ({
        key: submenu.key,
        menu: submenu.menu
      }))
    })) as Permission[];
  };

  const create = async ({
    customerId,
    data
  }: CreateParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.PROFILE_CREATE,
      {
        customer_id: customerId,
        permissions: cleanupPermissions(data.permissions),
        profile: data.profile
      }
    );
    return response.data;
  };

  const update = async ({
    customerId,
    data
  }: UpdateParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.PROFILE_UPDATE,
      {
        customer_id: customerId,
        permissions: cleanupPermissions(data.permissions),
        profile: data.profile
      }
    );
    return response.data;
  };

  const deleteByName = async ({
    customerId,
    name
  }: DeleteByNameParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.PROFILE_DELETE,
      {
        customer_id: customerId,
        profile: name
      }
    );
    return response.data;
  };

  const listProfileUsers = async ({
    customerId,
    profile,
    page
  }: ProfileUsersParams): Promise<NestedData<PaginatedProfileUsers>> => {
    const response: AxiosResponse<NestedData<PaginatedProfileUsers>> =
      await API.instance.post(APIEndpoints.PROFILE_LIST_USERS, {
        ["customer_id"]: customerId,
        profile,
        page
      });
    return response.data;
  };

  const updateUserProfile = async (
    data: updateUserProfileParams
  ): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.USER_UPDATE,
      {
        username: data.username,
        ["customer_id"]: data.customerId,
        ["profile"]: data.profile
      }
    );
    return response.data;
  };

  return {
    create,
    update,
    listAll,
    getByName,
    deleteByName,
    getDefault,
    listProfileUsers,
    updateUserProfile
  };
};

export default useProfileAPI;
