import {
  PaginatorModel,
  defaultPaginatorModelValues
} from "components/Paginator";
import useCaptureReportAPI, {
  CaptureReport,
  ListCaptureReportParams,
  DownloadCaptureReportParams,
  VehiclesMake
} from "api/CaptureReportAPI";
import {
  parseClassifierCoordinate,
  parsePlateCoordinate
} from "components/ImageViewer";
import Pages from "enums/Pages";
import useToolsAPI from "api/ToolsAPI";
import { EMPTY_VALUE } from "utils/String";
import fileDownload from "js-file-download";
import LinkPlate from "components/LinkPlate";
import DataTable from "components/DataTable";
import { useIsMount } from "hooks/useIsMount";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import CaptureReportFilter, {
  FormFilterCaptures
} from "pages/CaptureReport/CaptureReportFilter";
import { useLocale } from "contexts/LocaleContext";
import CustomFilterKeys from "enums/CustomFilterKeys";
import InnerPageLayout from "layouts/InnerPageLayout";
import { Download, Map, Sliders } from "react-feather";
import { useHistory, useParams } from "react-router-dom";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import useCameraAPI, { CameraFilter } from "api/CameraAPI";
import useLocationAPI, { Location } from "api/LocationAPI";
import CaptureImgButton from "components/CaptureImgButton";
import { FC, useCallback, useEffect, useState } from "react";
import snackNotification from "components/SnackNotification";
import PageSection from "components/PageSection/PageSection";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { Box, CircularProgress, Typography } from "@mui/material";
import { formatDateTime, setTimezone } from "utils/DateFunctions";
import CaptureImageViewerDialog, {
  CaptureImageDialog,
  initialCaptureImageDialogProps
} from "components/CaptureImageViewerDialog/CaptureImageViewerDialog";
import { endOfDay, format, parseISO, set, startOfDay } from "date-fns";
import HeatmapPlateDialog from "pages/CaptureReport/HeatmapPlateDialog";
import { captureReportTableColumns } from "utils/captureReportTableColumns";
import { LocationSection, usePageLocation } from "contexts/PageLocationContext";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";
import { CustomFilterProvider } from "components/CustomFilterMenu/CustomFilterContext";
import DownloadCaptureReportDialog from "pages/CaptureReport/DownloadCaptureReportDialog";
import { FormDownloadCaptureReport } from "pages/CaptureReport/DownloadCaptureReportDialog";
import { cepFormatter } from "utils/cepFormatter";

const CaptureReportPage: FC = () => {
  const [isFilterOpen, setFilterOpen] = useState(true);
  const [isDownloadCaptureReportOpen, setDownloadCaptureReportOpen] =
    useState(false);
  const [heatmapOpen, setHeatmapOpen] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isDownloadingCsv, setDownloadingCsv] = useState<boolean>(false);
  const [isDownloadingPdf, setDownloadingPdf] = useState<boolean>(false);
  const [reportResults, setReportResults] = useState<CaptureReport[]>([]);
  const [page, setPage] = useState(1);
  const [paginator, setPaginator] = useState<PaginatorModel>(
    defaultPaginatorModelValues
  );
  const { sessionUser } = useAuth();
  const { language } = useLocale();
  const LocationAPI = useLocationAPI();
  const ToolsAPI = useToolsAPI();
  const CaptureReportAPI = useCaptureReportAPI();
  const CameraAPI = useCameraAPI();

  const { t } = useTranslation();
  const { errorHandler } = useErrorHandler();

  const params = useParams<{ plate: string }>();
  const plate = params.plate ? decodeURIComponent(params.plate) : "";
  const [cameras, setCameras] = useState<CameraFilter[]>([]);
  const [equipmentsName, setEquipmentsName] = useState<string[]>([]);
  const [equipments, setEquipments] = useState<Location[]>([]);
  const [makes, setMakes] = useState<VehiclesMake[]>([]);

  useEffect(() => {
    (async () => {
      if (sessionUser) {
        const customerId = sessionUser["customer_id"];
        try {
          const [locationResponse, cameraResponse, makeResponse] =
            await Promise.all([
              LocationAPI.listAll(customerId),
              CameraAPI.listAll({ customerId }),
              ToolsAPI.listAllMakes()
            ]);

          const liteMake = makeResponse.make
            .filter(item => item.lite === true)
            .map(item => ({ name: item.name, isDivider: false }));
          liteMake[liteMake.length - 1].isDivider = true;
          const nonLiteMake = makeResponse.make
            .filter(item => item.lite === false)
            .map(item => ({ name: item.name, isDivider: false }));
          const makes = liteMake.concat(nonLiteMake);

          setMakes(makes || []);
          setCameras(
            [
              t("form.all"),
              ...cameraResponse.data.map(camera => ({
                ["location_name"]: camera["location_name"],
                ["camera_name"]: camera["camera_name"]
              }))
            ] || []
          );
          setEquipmentsName(
            [
              t("form.all"),
              ...locationResponse.data.map(
                location => location["location_name"]
              )
            ] || []
          );
          setEquipments(locationResponse.data);
        } catch (error) {
          errorHandler({ error });
        }
      }
    })();
  }, []);

  const defaultDate = new Date();

  const defaultFilterValues: FormFilterCaptures = {
    id: "",
    equipments: [],
    cameras: [],
    direction: "",
    street: "",
    number: "",
    city: "",
    state: "",
    zip_code: "",
    vehicleClasses: [],
    plate: "",
    startDate: setTimezone(
      set(defaultDate, { hours: 0, minutes: 0, seconds: 0 })
    ),
    endDate: setTimezone(defaultDate),
    startTime: setTimezone(startOfDay(defaultDate)),
    endTime: setTimezone(endOfDay(defaultDate)),
    make: "",
    model: "",
    color: ""
  };

  const [filter, setFilter] = useState<FormFilterCaptures>({
    ...defaultFilterValues,
    plate: plate || defaultFilterValues.plate,
    startDate: plate ? null : defaultFilterValues.startDate,
    endDate: plate ? null : defaultFilterValues.endDate,
    startTime: plate ? null : defaultFilterValues.startTime,
    endTime: plate ? null : defaultFilterValues.endTime
  });

  const defaultDownloadValues: FormDownloadCaptureReport = {
    formatType: "",
    tableColumnsSelected: []
  };

  const [dialogDownload, setDialogDownload] =
    useState<FormDownloadCaptureReport>({
      ...defaultDownloadValues
    });

  const history = useHistory();

  const requestData = useCallback(
    async (
      filterParam: FormFilterCaptures,
      pageValue: number,
      pageSizeValue: number
    ) => {
      if (!sessionUser?.["customer_id"]) return;
      const customerId = sessionUser["customer_id"];
      setLoading(true);
      try {
        if (
          !filterParam.plate &&
          history.location.pathname !== Pages.CAPTURE_REPORT
        ) {
          history.replace(Pages.CAPTURE_REPORT);
        }
        if (!plate && filterParam.plate) {
          history.replace(
            `${Pages.CAPTURE_REPORT}/${encodeURIComponent(filterParam.plate)}`
          );
        }

        let listFilterParam: ListCaptureReportParams = {
          ["customer_id"]: customerId,
          page: pageValue,
          ["page_size"]: pageSize * 10
        };

        listFilterParam = {
          ...listFilterParam,
          ["basic_filter"]: {
            ["initial_date"]: filterParam.startDate
              ? formatDateTime(
                  filterParam.startDate as Date,
                  filterParam.startTime as Date
                )
              : undefined,
            ["final_date"]: filterParam.endDate
              ? formatDateTime(
                  filterParam.endDate as Date,
                  filterParam.endTime as Date
                )
              : undefined,
            plate: filterParam.plate === "" ? undefined : filterParam.plate,
            ["id_capture"]: filterParam.id === "" ? undefined : filterParam.id,
            equipments:
              filterParam.equipments && filterParam.equipments?.length > 0
                ? filterParam.equipments
                : undefined,
            cameras:
              filterParam.cameras && filterParam.cameras.length > 0
                ? filterParam.cameras
                : undefined,
            ["vehicle_make"]:
              filterParam.make === "" ? undefined : filterParam.make,
            ["vehicle_model"]:
              filterParam.model === "" ? undefined : filterParam.model,
            ["vehicle_color"]:
              filterParam.color === "" ? undefined : filterParam.color,
            ["vehicle_classes"]:
              filterParam.vehicleClasses &&
              filterParam.vehicleClasses.length === 0
                ? undefined
                : filterParam.vehicleClasses,
            ["number_occurrences"]: filterParam.numberOccurrences
          }
        };

        // Generate a unique cache key considering the page size
        const CACHE_EXPIRATION_MINUTES = 1;
        const CACHE_KEY_PREFIX = "data_cache_";
        const cacheKey = `${CACHE_KEY_PREFIX}${pageSizeValue}`;
        const cachedData = localStorage.getItem(cacheKey);

        if (cachedData) {
          const { pages, lastPageVisited } = JSON.parse(cachedData);

          // Navigate forward
          if (lastPageVisited < pageValue && pages[pageValue] !== undefined) {
            const currentTime = new Date().getTime();
            const expirationTime = Number(CACHE_EXPIRATION_MINUTES) * 60 * 1000;
            const { timestamp } = pages[pageValue];

            // Update lastPageVisited regardless of cache expiration
            pages[pageValue] = pages[pageValue] || { data: [], timestamp: "0" };
            localStorage.setItem(
              cacheKey,
              JSON.stringify({ pages, lastPageVisited: pageValue })
            );

            const isCacheExpired =
              timestamp &&
              currentTime - parseInt(timestamp, 10) > expirationTime;

            if (!isCacheExpired) {
              setReportResults(pages[pageValue].data);
              setLoading(false);
              setPage(pageValue);
              return;
            }
            removeItemsWithPrefix(CACHE_KEY_PREFIX);
          }
        } else {
          removeItemsWithPrefix(CACHE_KEY_PREFIX);
        }

        const reportResponse = await CaptureReportAPI.list(listFilterParam);

        // Update the cache with the fresh data for the current page
        updateCache(cacheKey, {
          data: reportResponse.registers.items || [],
          timestamp: new Date().getTime().toString(),
          pageValue,
          pageSizeValue
        });

        const currentData = localStorage.getItem(cacheKey);

        if (currentData) {
          const { pages, lastPageVisited } = JSON.parse(currentData);
          if (lastPageVisited === 0) {
            setReportResults([]);
          } else {
            setReportResults(pages[pageValue].data);
          }
        }

        setPaginator({
          totalPages:
            Math.ceil(reportResponse.registers.total_items / pageSizeValue) ||
            0,
          totalItems: reportResponse.registers.total_items || 0
        });
        setPage(reportResponse.registers.page || 1);
      } catch (error) {
        errorHandler({ error });
      } finally {
        setLoading(false);
      }
    },
    [sessionUser]
  );

  const onDialogDownload = (dialogDownloadValue: FormDownloadCaptureReport) => {
    setDialogDownload(dialogDownloadValue);
  };

  const isMount = useIsMount();

  useEffect(() => {
    if (!isMount) {
      if (dialogDownload.formatType === "CSV") {
        downloadCsv();
      } else if (dialogDownload.formatType === "PDF") {
        downloadPdf();
      }
    }
  }, [dialogDownload]);

  const updateCache = (
    cacheKey: string,
    newData: {
      data: CaptureReport[];
      timestamp: string;
      pageValue: number;
      pageSizeValue: number;
    }
  ) => {
    let existingData: {
      pages: { [key: number]: { data: CaptureReport[]; timestamp: string } };
      lastPageVisited: number;
    } = {
      pages: {},
      lastPageVisited: 0
    };

    if (newData.data.length > 0) {
      // Retrieve existing data from the cache
      const cachedData = localStorage.getItem(cacheKey);

      if (cachedData) {
        existingData = JSON.parse(cachedData);
      }

      let page = newData.pageValue;
      let startIndex = 0;
      for (
        let index = 0;
        index < newData.data.length;
        index += newData.pageSizeValue
      ) {
        const endIndex = startIndex + newData.pageSizeValue;
        const pages = newData.data.slice(startIndex, endIndex);

        existingData.pages[page] = {
          data: [...pages],
          timestamp: newData.timestamp
        };
        page += 1;
        startIndex = endIndex;

        // Add a break condition if you have reached the end of the data
        if (endIndex >= newData.data.length) {
          break;
        }
      }
      existingData.lastPageVisited = newData.pageValue;
    }
    // Store the updated data in the cache
    localStorage.setItem(cacheKey, JSON.stringify(existingData));
  };

  const removeItemsWithPrefix = (prefix: string) => {
    // Iterate through all items in local storage
    for (const key in localStorage) {
      // Check if the key starts with the specified prefix
      if (key.startsWith(prefix)) {
        //setChangePageSize(true)
        // Remove the item from local storage
        localStorage.removeItem(key);
      }
    }
  };

  const getDownloadReportParams = (
    filter: FormFilterCaptures
  ): DownloadCaptureReportParams => {
    let customerId = "";
    if (sessionUser) customerId = sessionUser["customer_id"];

    let downloadParams: DownloadCaptureReportParams = {
      ["customer_id"]: customerId,
      language: language.split("-")[0],
      ["columns_selected"]: dialogDownload.tableColumnsSelected
    };
    downloadParams = {
      ...downloadParams,
      ["basic_filter"]: {
        ["initial_date"]: filter.startDate
          ? formatDateTime(filter.startDate as Date, filter.startTime as Date)
          : undefined,
        ["final_date"]: filter.endDate
          ? formatDateTime(filter.endDate as Date, filter.endTime as Date)
          : undefined,
        plate: filter.plate === "" ? undefined : filter.plate,
        ["id_capture"]: filter.id || undefined,
        cameras: filter.cameras?.length ? filter.cameras : undefined,
        equipments: filter.equipments?.length ? filter.equipments : undefined,
        ["vehicle_make"]: filter.make === "" ? undefined : filter.make,
        ["vehicle_model"]: filter.model === "" ? undefined : filter.model,
        ["vehicle_color"]: filter.color === "" ? undefined : filter.color,
        ["number_occurrences"]: filter.numberOccurrences?.length
          ? filter.numberOccurrences
          : undefined
      }
    };
    return downloadParams;
  };

  const downloadCsv = async () => {
    if (!filter || isDownloadingCsv || !sessionUser?.["customer_id"]) return;
    try {
      setDownloadingCsv(true);
      const response = await CaptureReportAPI.downloadCsv(
        getDownloadReportParams(filter)
      );
      fileDownload(response, "capture_report.csv", "text/csv");
      snackNotification.success(t("CaptureReportPage.downloadedSuccess"));
    } catch (error) {
      errorHandler({ error });
    } finally {
      setDownloadingCsv(false);
    }
  };

  const forceDownloadPdf = (pdf: string) => {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "capture_report.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const downloadPdf = async () => {
    if (!filter || isDownloadingPdf || !sessionUser?.["customer_id"]) return;
    try {
      setDownloadingPdf(true);
      const response = await CaptureReportAPI.downloadPdf(
        getDownloadReportParams(filter)
      );
      forceDownloadPdf(response);
      snackNotification.success(t("CaptureReportPage.downloadedSuccess"));
    } catch (error) {
      errorHandler({ error });
    } finally {
      setDownloadingPdf(false);
    }
  };

  const [capture, setCapture] = useState<CaptureImageDialog>(
    initialCaptureImageDialogProps
  );
  const [isCaptureDialogOpen, setCaptureDialogOpen] = useState<boolean>(false);

  const getCaptureReportCoord = (capture: CaptureReport) => {
    // Check CaptureReport first
    if (capture.latitude && capture.longitude) {
      return { latitude: capture.latitude, longitude: capture.longitude };
    }
    // Search equipments list
    const coord = equipments.find(
      item => item?.["location_name"] === capture?.equipment
    );
    return {
      latitude: coord?.["location_data"]["latitude"] ?? "",
      longitude: coord?.["location_data"]["longitude"] ?? ""
    };
  };

  const openCaptureDialog = (capture: CaptureReport) => {
    const coord = getCaptureReportCoord(capture);
    const vehicleCategory = t(
      `VehicleOverviewPage.${
        capture.plate === "0000000" ? "unknown" : capture.vehicle_class
      }`
    );
    setCapture({
      id: capture.id,
      image: capture.imageLink,
      equipment: capture.equipment,
      camera: capture.camera,
      dateTime: capture["date_capture"],
      plate: capture.plate,
      plateCoordinate: capture.plate_coordinate
        ? parsePlateCoordinate(capture.plate_coordinate)
        : undefined,
      ["classifier_list"]: capture.classifier_list
        ? parseClassifierCoordinate(capture.classifier_list)
        : undefined,
      longitude: coord["longitude"],
      latitude: coord["latitude"],
      renavam: {
        vehicleMake: capture.vehicle_make,
        vehicleModel: capture.vehicle_model,
        vehicleColor: capture.vehicle_color,
        vehicleCategory: vehicleCategory.toUpperCase(),
        vehicleYear: capture.vehicle_year
      }
    });
    setCaptureDialogOpen(true);
  };

  const { setPageTitle, setLocation } = usePageLocation();

  useEffect(() => {
    const title = plate
      ? t("windowTitle.captureReportPlate", { plate })
      : t("windowTitle.captureReport");
    setPageTitle(title);
    const location: LocationSection[] = [
      {
        label: t("menu.reports")
      },
      {
        label: t("CaptureReportPage.title"),
        page: Pages.CAPTURE_REPORT
      }
    ];
    setReportResults([]);
    setPaginator(defaultPaginatorModelValues);
    setPage(1);
    if (plate && plate.length > 0) {
      requestData({ ...filter, plate }, page, pageSize);
      setFilter({ ...defaultFilterValues, plate });
      location.push({
        label: plate,
        page: `${Pages.CAPTURE_REPORT}/${encodeURIComponent(plate)}`
      });
    } else {
      setFilter(defaultFilterValues);
    }
    setLocation(location);
  }, [t, plate]);

  useEffect(() => {
    if (plate.length > 0) {
      requestData(filter, page, pageSize);
    }
  }, [requestData, pageSize, Pages]);

  const onFilter = (filterValue: FormFilterCaptures) => {
    setFilter(filterValue);
    requestData(filterValue, page, pageSize);
  };

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <PageSection
          title={t("CaptureReportPage.title")}
          isLoading={isLoading}
          actions={
            <>
              {plate && (
                <PageSectionHeaderAction
                  startIcon={<Map />}
                  onClick={() => setHeatmapOpen(true)}
                >
                  {t("DefaultPageLayout.map")}
                </PageSectionHeaderAction>
              )}
              <>
                <PageSectionHeaderAction
                  variant="outlined"
                  disabled={paginator.totalItems < 1}
                  color="primary"
                  startIcon={
                    isDownloadingCsv || isDownloadingPdf ? (
                      <CircularProgress size={22} color="secondary" />
                    ) : (
                      <Download />
                    )
                  }
                  onClick={() => setDownloadCaptureReportOpen(true)}
                >
                  {t("action.export")}
                </PageSectionHeaderAction>
              </>
            </>
          }
          primaryActions={
            <>
              <PageSectionHeaderAction
                variant="contained"
                color="secondary"
                startIcon={<Sliders />}
                onClick={() => setFilterOpen(true)}
              >
                {t("action.filter")}
              </PageSectionHeaderAction>
            </>
          }
        >
          <CustomFilterProvider filterKey={CustomFilterKeys.CAPTURE_REPORT}>
            <CaptureReportFilter
              open={isFilterOpen}
              cameras={cameras}
              equipments={equipmentsName}
              makes={makes}
              setOpen={setFilterOpen}
              filter={filter}
              defaultValues={defaultFilterValues}
              onFilter={onFilter}
            />
          </CustomFilterProvider>
          <Box>
            <DataTable
              watermarked
              headers={[
                {
                  key: captureReportTableColumns[0].key,
                  label: t(
                    `CaptureReportPage.${captureReportTableColumns[0].label}`
                  )
                },
                {
                  key: captureReportTableColumns[1].key,
                  label: t(
                    `CaptureReportPage.${captureReportTableColumns[1].label}`
                  )
                },
                {
                  key: captureReportTableColumns[2].key,
                  label: t(
                    `CaptureReportPage.${captureReportTableColumns[2].label}`
                  )
                },
                {
                  key: captureReportTableColumns[3].key,
                  label: t(
                    `CaptureReportPage.${captureReportTableColumns[3].label}`
                  )
                },
                {
                  key: captureReportTableColumns[4].key,
                  label: t(
                    `CaptureReportPage.${captureReportTableColumns[4].label}`
                  )
                },
                {
                  key: captureReportTableColumns[5].key,
                  label: t(
                    `CaptureReportPage.${captureReportTableColumns[5].label}`
                  )
                },
                {
                  key: captureReportTableColumns[6].key,
                  label: t(
                    `CaptureReportPage.${captureReportTableColumns[6].label}`
                  )
                },
                {
                  key: captureReportTableColumns[7].key,
                  label: t(
                    `CaptureReportPage.${captureReportTableColumns[7].label}`
                  )
                },
                {
                  key: captureReportTableColumns[8].key,
                  label: t(
                    `CaptureReportPage.${captureReportTableColumns[8].label}`
                  )
                },
                {
                  key: "capture",
                  label: t("CaptureReportPage.capture"),
                  noSort: true
                }
              ]}
              defaultSort={["id", "asc"]}
              onHeaderSort={setReportResults}
              data={reportResults}
              renderRow={row => [
                <>{row.id}</>,
                <>
                  {format(
                    parseISO(row["date_capture"]),
                    t("CaptureReportPage.dateFormat")
                  )}
                </>,
                <>{row.equipment}</>,
                <>{row.camera}</>,
                <>{t(`CameraDirection.${row.side}`)}</>,
                <>
                  {(row.state === "" && row.city === "") ||
                  (row.state === "ND" && row.city === "ND") ? (
                    <Typography component="div" variant="caption">
                      {t("EquipmentsPage.noAddress")}
                    </Typography>
                  ) : (
                    <>
                      <Typography component="div" variant="caption">
                        <strong>{`${row.street}, ${row.number}`}</strong>
                      </Typography>
                      <Typography color="textSecondary" variant="caption">
                        {`${cepFormatter(row.zip_code)},
                          ${row.city} - ${row.state}`}
                      </Typography>
                    </>
                  )}
                </>,
                <>
                  <LinkPlate plate={row.plate} path={Pages.VEHICLE_OVERVIEW} />
                </>,
                <>
                  <div>{row["vehicle_make"] || EMPTY_VALUE}</div>
                  <div>{row["vehicle_model"] || EMPTY_VALUE}</div>
                  <div>{row["vehicle_color"] || EMPTY_VALUE}</div>
                </>,
                <>
                  {t(
                    `MonitoredVehiclesDashboardPage.${
                      row.plate && row.plate === "0000000"
                        ? "unknown"
                        : row.vehicle_class
                    }`
                  )}
                </>,
                <>
                  <CaptureImgButton
                    imageLink={row.imageLink}
                    onClick={() => openCaptureDialog(row)}
                  />
                </>
              ]}
              hideColumnsSm={[5, 6]}
              hideColumnsXs={[0, 2, 3, 5, 6]}
              page={page}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
              totalPages={paginator.totalPages}
              totalItems={paginator.totalItems}
              isLoading={isLoading}
            />
          </Box>
        </PageSection>
      </InnerPageLayout>
      <CaptureImageViewerDialog
        open={isCaptureDialogOpen}
        setOpen={setCaptureDialogOpen}
        capture={capture}
        setCapture={(capture: CaptureImageDialog) => {
          setCapture(capture);
          setReportResults(
            reportResults.map(report =>
              report.id === capture.id
                ? { ...report, plate: capture.plate }
                : report
            )
          );
        }}
      />
      <HeatmapPlateDialog
        open={heatmapOpen}
        setOpen={setHeatmapOpen}
        filterData={filter}
      />
      <DownloadCaptureReportDialog
        open={isDownloadCaptureReportOpen}
        download={defaultDownloadValues}
        setOpen={setDownloadCaptureReportOpen}
        defaultValues={defaultDownloadValues}
        onDialogDownload={onDialogDownload}
      />
    </DefaultPageLayout>
  );
};

export default CaptureReportPage;
