import { Box, Grid, Tabs, Tab, Typography } from "@mui/material";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import InputField from "components/InputField";
import { FC, useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAuth } from "contexts/AuthContext";
import useProfileAPI, { Permission, Profile } from "api/ProfileAPI";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import FormDialog from "components/FormDialog";
import PermissionsTable from "./PermissionsTable";
import AddProfileDialog from "./AddProfileDialog";
import PageSection from "components/PageSection/PageSection";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";
import { MinusCircle, Plus } from "react-feather";
import DataTable from "components/DataTable";
import TableSortLabel from "@mui/material/TableSortLabel";
import snackNotification from "components/SnackNotification";

const ProfileFormDialog: FC<{
  profile: Profile;
  open: boolean;
  setOpen: (open: boolean) => void;
  updateCallerContent: () => void;
  defaultProfile: Profile;
}> = ({ profile, open, setOpen, updateCallerContent, defaultProfile }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const isCreating = profile.profile === "";
  const [activeTab, setActiveTab] = useState(0);
  const { sessionUser } = useAuth();
  const ProfileAPI = useProfileAPI();
  const { errorHandler } = useErrorHandler();
  const [updatedProfile, setUpdatedProfile] = useState<Profile>(profile);
  const { control, formState, handleSubmit, reset } = useForm<Profile>({
    mode: "onChange",
    defaultValues: defaultProfile
  });
  const [profileUsers, setProfileUsers] = useState<string[]>([]);
  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
  const [userSearch, setUserSearch] = useState("");
  const { updateUserProfile } = useProfileAPI();
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<string>("username");

  useEffect(() => {
    setUpdatedProfile({ ...profile });
    reset(profile);
  }, [reset, profile, open]);

  const handleTabChange = (newValue: number) => {
    setActiveTab(newValue);
  };

  const onSubmit = async (data: Profile) => {
    const newProfile: Profile = {
      profile: data.profile,
      permissions: updatedProfile.permissions
    };

    if (!sessionUser?.["customer_id"]) return;
    setLoading(true);
    try {
      if (isCreating) {
        await ProfileAPI.create({
          customerId: sessionUser["customer_id"],
          data: newProfile
        });
      } else {
        await ProfileAPI.update({
          customerId: sessionUser["customer_id"],
          data: newProfile
        });
      }
      setOpen(false);
      setActiveTab(0);
      updateCallerContent();
    } catch (error) {
      errorHandler({ error });
    } finally {
      snackNotification.success(
        isCreating
          ? t("ProfilesPage.profileCreated")
          : t("ProfilesPage.profileUpdated")
      );
      setLoading(false);
    }
  };

  const handlePermissionChange = (updatedPermission: Permission[]) => {
    setUpdatedProfile({
      ...profile,
      permissions: updatedPermission
    });
  };

  const requestUsers = useCallback(async () => {
    if (!sessionUser || profile.profile.length === 0) return;
    setLoading(true);
    try {
      const response = await ProfileAPI.listProfileUsers({
        customerId: sessionUser["customer_id"],
        profile: profile.profile,
        page: 1
      });
      if (response) {
        setProfileUsers(response.data.users);
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setLoading(false);
    }
  }, [sessionUser, profile]);

  const handleRemoveUser = async (selectedUsername: string) => {
    if (!sessionUser?.["customer_id"]) return;
    const updateData = {
      customerId: sessionUser["customer_id"],
      profile: "Usuário",
      username: selectedUsername
    };
    try {
      await updateUserProfile(updateData);
    } catch (error) {
      errorHandler({ error });
    } finally {
      snackNotification.success(t("EditUserGroupsPage.userRemoved"));
      setProfileUsers([
        ...profileUsers.filter(user => user !== selectedUsername)
      ]);
    }
  };

  useEffect(() => {
    requestUsers();
  }, [requestUsers, profile]);

  const openAddUserDialog = () => {
    setAddUserDialogOpen(true);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    const sortedUsers = [...profileUsers].sort((a, b) => {
      if (isAsc) {
        return a.localeCompare(b);
      }
      return b.localeCompare(a);
    });
    setProfileUsers(sortedUsers);
  };

  return (
    <FormDialog
      onConfirm={handleSubmit(onSubmit)}
      open={open}
      setOpen={setOpen}
      isLoading={isLoading}
      dirty={formState.isDirty}
      confirmDisabled={!formState.isValid}
      confirmText={t("action.apply")}
      title={
        isCreating
          ? t("ProfilesPage.new")
          : `${t("ProfilesPage.edit")}: ${profile.profile}`
      }
      maxWidth="lg"
    >
      <Box sx={{ width: "100%", paddingBottom: "10px" }}>
        <Tabs
          value={activeTab}
          onChange={(_event, value) => {
            handleTabChange(value);
          }}
          aria-label="profile tabs"
        >
          <Tab
            label={t("ProfilesPage.permissions")}
            sx={{ paddingTop: "0", marginTop: "0" }}
          />
          <Tab
            label={t("ProfilesPage.users")}
            sx={{ paddingTop: "0", marginTop: "0" }}
          />
        </Tabs>
      </Box>

      {activeTab === 0 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="profile"
              control={control}
              rules={{
                required: t("form.requiredField").toString()
              }}
              render={({ field, fieldState }) => (
                <InputField
                  label={t("ProfilesPage.name")}
                  customProps={{
                    disabled: isLoading,
                    readOnly: !isCreating,
                    required: true
                  }}
                  field={{ ...field }}
                  fieldState={fieldState}
                />
              )}
            />
          </Grid>
          <Grid xs={12} sx={{ padding: 0 }}>
            <Box sx={{ maxHeight: "60vh", overflow: "auto", padding: 0 }}>
              <PermissionsTable
                permissions={updatedProfile.permissions}
                onPermissionChange={handlePermissionChange}
                defaultPermissions={defaultProfile.permissions}
              />
            </Box>
          </Grid>
        </Grid>
      )}

      {activeTab === 1 && (
        <Box>
          <PageSection
            search={
              profileUsers.length > 0
                ? {
                    onChange: e => setUserSearch(e.target.value),
                    placeholder: t("ProfilesPage.filterUser")
                  }
                : undefined
            }
            primaryActions={
              <PageSectionHeaderAction
                variant="contained"
                color="secondary"
                startIcon={<Plus />}
                onClick={openAddUserDialog}
              >
                {t("EditUserGroupsPage.addUser")}
              </PageSectionHeaderAction>
            }
          ></PageSection>
          {isLoading && (
            <Typography align="center">{t("waitState.loading")}</Typography>
          )}
          {!isLoading && (
            <Box>
              <DataTable
                headers={[
                  {
                    key: "username",
                    label: (
                      <TableSortLabel
                        active={orderBy === "username"}
                        direction={orderBy === "username" ? order : "asc"}
                        onClick={() => handleRequestSort("username")}
                      >
                        {t("UsersPage.users")}
                      </TableSortLabel>
                    ),
                    noSort: true
                  },
                  {
                    key: "actions",
                    label: t("UsersPage.actions"),
                    align: "right",
                    noSort: true
                  }
                ]}
                defaultSort={["username", "asc"]}
                data={profileUsers
                  .filter(username =>
                    username.toLowerCase().includes(userSearch.toLowerCase())
                  )
                  .map(username => ({ key: username }))}
                renderRow={user => [
                  <Typography component="div" variant="caption" key={user.key}>
                    <strong>{user.key}</strong>
                  </Typography>,
                  <Button
                    key={user.key}
                    customProps={{
                      color: "primary",
                      variant: "outlined",
                      size: "small",
                      onClick: () => handleRemoveUser(user.key),
                      startIcon: <MinusCircle size={16} />
                    }}
                  >
                    {t("action.remove")}
                  </Button>
                ]}
                hideColumnsSm={[2, 3]}
                hideColumnsXs={[1, 2, 3]}
                page={1}
                onPageChange={() => {}}
                pageSize={10}
                totalPages={1}
                totalItems={profileUsers.length}
                isLoading={isLoading}
              />
            </Box>
          )}
          <AddProfileDialog
            open={addUserDialogOpen}
            setOpen={setAddUserDialogOpen}
            updateCallerContent={(newUsers: string[]) => {
              setProfileUsers([...newUsers, ...profileUsers]);
            }}
            profile={profile.profile}
            profileUsers={profileUsers}
          />
        </Box>
      )}
    </FormDialog>
  );
};

export default ProfileFormDialog;
