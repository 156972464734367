import GoogleMapReact, {
  ChangeEventValue,
  Coords,
  MapOptions,
  Maps,
  Position
} from "google-map-react";
import { Box } from "@mui/material";
import { FC, MouseEventHandler, ReactNode, useCallback, useState } from "react";
import { useLocale } from "contexts/LocaleContext";
import MapMarker from "components/MapMarker";
import { setMonitoringVehiclesColor } from "utils/String";

const key = process.env.REACT_APP_MAPS_API_KEY ?? "__ENV_VARIABLE_MISSING__";
const defaultMinHeight = 300;
const defaultZoom = 12;
const closeZoom = 15;
const defaultRadius = 20;
const defaultOpacity = 0.6;
const defaultCenter = { lat: -25.431040332176853, lng: -49.2434949242968 };

export type MapGeolocation = {
  lat: number;
  lng: number;
  weight?: number;
  type?:
    | "documentation"
    | "intelligence"
    | "search_and_seizure"
    | "theft_and_robbery"
    | "automatic_monitoring"
    | "equipment";
  style?: "pin" | "circle" | "car" | "video" | "mobile" | "equipment";
  color?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

const createMapOptions = (maps: Maps): MapOptions =>
  // next props are exposed at maps
  // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
  // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
  // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
  // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
  // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
  ({
    fullscreenControl: false,
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_TOP
    },
    minZoom: 0
  });

type Props = {
  minHeight?: number;
  initialCenter?: Coords;
  customControls?: ReactNode;
  heatmapPositions?: Position[];
  onLoad?: (map: google.maps.Map) => void;
  onChange?: (value: ChangeEventValue) => void;
  geolocations?: MapGeolocation[];
};

const Map: FC<Props> = ({
  minHeight = defaultMinHeight,
  initialCenter,
  onChange,
  customControls,
  children,
  heatmapPositions,
  onLoad,
  geolocations = []
}) => {
  const [center, setCenter] = useState<Coords | undefined>(initialCenter);
  const [zoom, setZoom] = useState(initialCenter ? closeZoom : defaultZoom);
  const mapKey = JSON.stringify({ center, geolocations });
  const { language } = useLocale();
  const [mapLanguage, mapRegion] = language.split("-");

  const fitMapToBounds = useCallback(
    (map: google.maps.Map | null) => {
      if (!map) return;
      const bounds = new google.maps.LatLngBounds();
      geolocations.forEach(location => {
        if (
          !isNaN(location.lat) &&
          !isNaN(location.lng) &&
          location.lat !== 0 &&
          location.lng !== 0
        ) {
          bounds.extend(new google.maps.LatLng(location.lat, location.lng));
        }
      });
      if (geolocations.length > 0 && !bounds.isEmpty()) {
        map.fitBounds(bounds);
      }
      if (geolocations.length <= 1) {
        map.setZoom(defaultZoom);
      }
    },
    [geolocations]
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        minHeight,
        position: "relative"
      }}
    >
      <GoogleMapReact
        key={mapKey}
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{
          key,
          region: mapRegion,
          language: mapLanguage,
          libraries: ["visualization", "places"]
        }}
        onGoogleApiLoaded={({ map }) => {
          if (onLoad && map) {
            onLoad(map);
          }
          if (map && !center) fitMapToBounds(map);
        }}
        defaultCenter={defaultCenter}
        center={center}
        defaultZoom={defaultZoom}
        zoom={zoom}
        options={createMapOptions}
        onChange={onChange}
        heatmap={{
          positions: heatmapPositions ?? [],
          options: {
            radius: defaultRadius,
            opacity: defaultOpacity
          }
        }}
      >
        {geolocations
          .filter(
            location =>
              location.lat &&
              location.lng &&
              location.lat !== 0 &&
              location.lng !== 0
          )
          .map((location, idx) => {
            const latitude =
              location.lat > 90 ? 90 : location.lat < -90 ? -90 : location.lat;
            const longitude =
              location.lng > 180
                ? 180
                : location.lng < -180
                ? -180
                : location.lng;
            const markerProps: MapGeolocation = {
              lat: latitude,
              lng: longitude
            };
            if (location.weight) markerProps.weight = location.weight;
            if (location.type) markerProps.type = location.type;
            if (location.style) markerProps.style = location.style;
            if (location.type) {
              markerProps.type = location.type;
              markerProps.color = setMonitoringVehiclesColor(location.type);
            }
            if (location.color) markerProps.color = location.color;
            markerProps.onClick = event => {
              setCenter({ lat: latitude, lng: longitude });
              setZoom(closeZoom);
              if (location.onClick) {
                location.onClick(event);
              }
            };
            return (
              <MapMarker
                key={`euipment-${idx}`}
                {...markerProps}
                onClick={markerProps.onClick}
              />
            );
          })}
        {children}
      </GoogleMapReact>
      {customControls}
    </Box>
  );
};

export default Map;
