import {
  Box,
  Grid,
  Divider,
  IconButton,
  Typography,
  CircularProgress
} from "@mui/material";
import Map from "components/Map";
import { X } from "react-feather";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import { MapAddress } from "./EquipmentsFormDialog";
import GoogleMapReact from "google-map-react";
import { useEquipmentMap } from "contexts/EquipmentMapContext";
import SearchPlace from "pages/Settings/Equipments/SearchPlace";

interface EquipmentsAddressMapProps {
  setOpenMap: (open: boolean) => void;
  onSetNewAddress: (address: MapAddress) => void;
}

const EquipmentsAddressMap: FC<EquipmentsAddressMapProps> = ({
  setOpenMap,
  onSetNewAddress
}) => {
  const { t } = useTranslation();
  const [address, setAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { setCenter, setZoom, zoom } = useEquipmentMap();
  const [pinPosition, setPinPosition] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);

  useEffect(() => {
    setCenter({
      lat: -15.780132,
      lng: -47.929292
    });
    setZoom(3);
  }, []);

  const handleMapClick = async (mapEvent: GoogleMapReact.ClickEventValue) => {
    try {
      if (mapEvent) {
        setIsLoading(true);
        const service = new google.maps.Geocoder();
        const response = await service.geocode({
          location: {
            lat: mapEvent.lat,
            lng: mapEvent.lng
          }
        });
        const res = response as google.maps.GeocoderResponse;
        if (res.results) {
          const location = res.results[0];
          const newAddress = formatAddress(location);
          onSetNewAddress({
            number: newAddress.number,
            street: newAddress.street,
            city: newAddress.city,
            state: newAddress.state,
            zipCode: newAddress.zipCode,
            latitude: mapEvent.lat.toFixed(6).toString(),
            longitude: mapEvent.lng.toFixed(6).toString()
          });
          setPinPosition({
            latitude: mapEvent.lat,
            longitude: mapEvent.lng
          });
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddressChange = (address: string) => {
    setAddress(address);
  };

  const formatAddress = (location: google.maps.GeocoderResult): MapAddress => {
    const currentAddress: MapAddress = {
      zipCode: "",
      latitude: "",
      longitude: "",
      city: "",
      number: "",
      street: "",
      state: ""
    };

    for (let i = 0; i < location.address_components.length; i++) {
      for (let j = 0; j < location.address_components[i].types.length; j++) {
        const type = location.address_components[i].types[j];
        if (type === "administrative_area_level_1" && !currentAddress.state) {
          currentAddress.state = location.address_components[i].long_name;
        }
        if (type === "administrative_area_level_2" && !currentAddress.city) {
          currentAddress.city = location.address_components[i].long_name;
        }
        if (type === "street_number" && !currentAddress.number) {
          currentAddress.number = location.address_components[i].long_name;
        }
        if (type === "route" && !currentAddress.street) {
          currentAddress.street = location.address_components[i].long_name;
        }
        if (type === "postal_code" && !currentAddress.zipCode) {
          currentAddress.zipCode = location.address_components[i].long_name;
        }
      }
    }

    return currentAddress;
  };

  useEffect(() => {
    if (address) {
      (async () => {
        const service = new google.maps.Geocoder();
        const response = await service.geocode({ address });
        const res = response as google.maps.GeocoderResponse;
        if (res.results) {
          const { location } = res.results[0].geometry;
          const newAddress = formatAddress(res.results[0]);
          setCenter({
            lat: location.lat(),
            lng: location.lng()
          });
          setZoom(16);
          onSetNewAddress({
            number: newAddress.number,
            street: newAddress.street,
            city: newAddress.city,
            state: newAddress.state,
            zipCode: newAddress.zipCode,
            latitude: location.lat().toFixed(6).toString(),
            longitude: location.lng().toFixed(6).toString()
          });
          setPinPosition({
            latitude: location.lat(),
            longitude: location.lng()
          });
        }
      })();
    }
  }, [address]);

  return (
    <>
      <Grid item xs={12}>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Typography align="left" variant="body1" color="textSecondary">
            <strong>{t("EquipmentsPage.localizationMap")}</strong>
          </Typography>
          <IconButton
            onClick={() => {
              setOpenMap(false);
              setPinPosition(null);
            }}
          >
            <X />
          </IconButton>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ mb: 2 }} />
      </Grid>
      <Grid item xs={12} sx={{ mb: 1 }}>
        <SearchPlace onChange={handleAddressChange} />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          div: { cursor: "crosshair" },
          position: "relative",
          overflow: "hidden"
        }}
      >
        {isLoading && (
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 111,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(255,255,255,0.7)"
            }}
          >
            <CircularProgress color="secondary" />
          </Box>
        )}
        <Map
          onClick={handleMapClick}
          zoom={zoom}
          geolocations={
            pinPosition
              ? [
                  {
                    lat: pinPosition.latitude,
                    lng: pinPosition.longitude,
                    color: "#26abfc"
                  }
                ]
              : []
          }
        />
      </Grid>
    </>
  );
};

export default EquipmentsAddressMap;
