import {
  Box,
  Checkbox,
  FormControlLabel,
  Chip,
  Grid,
  Link,
  MenuItem,
  OutlinedInput,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import useAuthAPI from "api/AuthAPI";
import useUserAPI from "api/UserAPI";
import { orderBy, uniqBy } from "lodash";
import usePermissionAPI from "api/PermissionAPI";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import InputField from "components/InputField";
import RegexPatterns from "utils/RegexPatterns";
import SelectField from "components/SelectField";
import PasswordField from "components/PasswordField";
import { Controller, useForm } from "react-hook-form";
import snackNotification from "components/SnackNotification";
import { FC, useCallback, useEffect, useState } from "react";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import FormDialog from "components/FormDialog";

type UserForm = {
  name: string;
  status: string;
  username: string;
  password: string;
  permissionLevel: string;
  permissionSubLevels: string[];
  ignoreUserGroup: boolean;
  isAPI: boolean;
};

const defaultValues: UserForm = {
  name: "",
  status: "",
  username: "",
  password: "",
  permissionLevel: "",
  permissionSubLevels: [],
  ignoreUserGroup: false,
  isAPI: false
};

type Props = {
  username: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  updateCallerContent: () => void;
};

const UserFormDialog: FC<Props> = ({
  username,
  open,
  setOpen,
  updateCallerContent
}) => {
  const { t } = useTranslation();
  const isCreatingUser = username === "";
  const [isLoadingUser, setLoadingUser] = useState<boolean>(false);
  const { resendAccessCode, sendPasswordResetCode } = useAuthAPI();
  const { getByUsername, create, update } = useUserAPI();
  const PermissionAPI = usePermissionAPI();

  const { errorHandler } = useErrorHandler();
  const { changeSessionUser, sessionUser } = useAuth();

  const [permissionLevels, setPermissionLevels] = useState<
    { levelName: string; subLevelName: string }[]
  >([]);
  const [user, setUser] = useState<UserForm>(defaultValues);
  const [selectedSubLevels, setSelectedSubLevels] = useState<string[]>([]);
  const [permissionSubLevels, setPermissionSubLevels] = useState<string[]>([]);
  const [isEditingPumatronixUser, setIsEditingPumatronixUser] = useState(false);

  const { control, formState, handleSubmit, reset, setValue, watch } =
    useForm<UserForm>({
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues
    });

  const isPumatronixUser = sessionUser?.level_name === "Pumatronix";

  const requestData = useCallback(async () => {
    if (!sessionUser?.["customer_id"]) return;
    const customerId = sessionUser["customer_id"];
    setLoadingUser(true);
    try {
      const [permissionResponse, userResponse] = await Promise.all([
        PermissionAPI.listAll(),
        isCreatingUser
          ? Promise.resolve(null)
          : getByUsername(customerId, username)
      ]);

      let levelPermissions = permissionResponse.data.map(permission => ({
        levelName: permission["level_name"],
        subLevelName: permission["sub_level_name"]
      }));
      if (!isPumatronixUser) {
        levelPermissions = levelPermissions.filter(
          l => l.levelName !== "Pumatronix"
        );
      }
      setPermissionLevels(levelPermissions);

      if (!isCreatingUser && userResponse) {
        setUser({
          username: userResponse.username,
          password: "",
          status: userResponse.status,
          name: userResponse.name,
          permissionLevel: userResponse.level_name || "",
          permissionSubLevels: userResponse.sub_level_name || [],
          ignoreUserGroup:
            userResponse["ignore_user_group"] || defaultValues.ignoreUserGroup,
          isAPI: userResponse.is_api || false
        });
        setIsEditingPumatronixUser(userResponse.level_name === "Pumatronix");
        setSelectedSubLevels(userResponse.sub_level_name || []);
        setPermissionSubLevels(
          levelPermissions
            .filter(p => p.levelName === userResponse.level_name)
            .map(p => p.subLevelName)
        );
      } else {
        setSelectedSubLevels([]);
        setPermissionSubLevels([]);
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setLoadingUser(false);
    }
  }, [isCreatingUser, reset, sessionUser, t, username]);

  useEffect(() => {
    if (open) {
      requestData();
    }
  }, [requestData, open]);

  useEffect(() => {
    reset(user);
  }, [setValue, user]);

  useEffect(() => {
    if (!open) {
      setUser(defaultValues);
      setSelectedSubLevels([]);
      reset(defaultValues);
      setIsEditingPumatronixUser(false);
    }
  }, [open]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "permissionLevel") {
        setSelectedSubLevels([]);
        const subLevelsOptions = permissionLevels
          .filter(
            p =>
              p.levelName === value.permissionLevel &&
              p.subLevelName !== "VideoWall" &&
              p.subLevelName !== "Móvel"
          )
          .map(p => p.subLevelName);
        setPermissionSubLevels(subLevelsOptions);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, permissionLevels]);

  const onSubmit = async (data: UserForm) => {
    if (!sessionUser?.["customer_id"]) return;
    setLoadingUser(true);
    const newData = { ...data };
    newData.permissionSubLevels = selectedSubLevels;
    try {
      if (isCreatingUser) {
        await create({
          customerId: sessionUser["customer_id"],
          name: newData.name,
          username: newData.username,
          password: newData.password,
          levelName: newData.permissionLevel,
          subLevelName: newData.permissionSubLevels,
          ignoreUserGroup: newData.ignoreUserGroup,
          isAPI: newData.isAPI
        });
        snackNotification.success(t("UsersPage.userCreated"));
      } else {
        await update({
          customerId: sessionUser["customer_id"],
          name: newData.name,
          username: newData.username,
          levelName: newData.permissionLevel,
          subLevelName: newData.permissionSubLevels,
          ignoreUserGroup: newData.ignoreUserGroup,
          isAPI: newData.isAPI
        });
        if (sessionUser) {
          changeSessionUser({
            ...sessionUser,
            name: newData.name
          });
        }
        snackNotification.success(t("UsersPage.userUpdated"));
      }
      updateCallerContent();
      setOpen(false);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setLoadingUser(false);
    }
  };

  const resendCode = async () => {
    setLoadingUser(true);
    try {
      await resendAccessCode({ username: user.username });
    } catch (error) {
      errorHandler({ error });
    } finally {
      setLoadingUser(false);
    }
  };

  const forgotPassword = async () => {
    setLoadingUser(true);
    try {
      await sendPasswordResetCode({ username: user.username });
    } catch (error) {
      errorHandler({ error });
    } finally {
      setLoadingUser(false);
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <FormDialog
      onConfirm={handleSubmit(onSubmit)}
      open={open}
      setOpen={setOpen}
      isLoading={isLoadingUser}
      dirty={formState.isDirty}
      confirmDisabled={!formState.isValid || !selectedSubLevels.length}
      confirmText={t("action.apply")}
      title={
        isCreatingUser ? t("UsersPage.createANewUser") : t("UsersPage.editUser")
      }
      actions={
        !isCreatingUser && (
          <Box
            sx={{
              mb: isMobile ? 2 : 0
            }}
          >
            {user.status === "INITIAL_BLOCKED" ? (
              <Link onClick={() => resendCode()} color="secondary">
                {t("UsersPage.resendCode")}
              </Link>
            ) : (
              <Link onClick={() => forgotPassword()} color="secondary">
                {t("UsersPage.resetPassword")}
              </Link>
            )}
          </Box>
        )
      }
    >
      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12}>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body1" align="left" color="textSecondary">
              <strong>{t("UserFormDialog.basicInfo")}</strong>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field, fieldState }) => (
              <InputField
                label={t("UserFormDialog.name")}
                customProps={{
                  disabled: isLoadingUser,
                  required: true
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ my: 2 }}>
            <Typography variant="body1" align="left" color="textSecondary">
              <strong>{t("UserFormDialog.accountInfo")}</strong>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Controller
            name="username"
            control={control}
            rules={{
              required: t("form.requiredField").toString(),
              pattern: {
                value: RegexPatterns.EMAIL_ADDRESS,
                message: t("form.invalidFormat")
              }
            }}
            render={({ field, fieldState }) => (
              <InputField
                label={t("UserFormDialog.email")}
                customProps={{
                  readOnly: !isCreatingUser,
                  type: "email",
                  autoComplete: "off",
                  inputProps: {
                    autoComplete: "off"
                  },
                  disabled: isLoadingUser,
                  required: true
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        {isCreatingUser && (
          <Grid item xs={12} sm={6}>
            <Controller
              name="password"
              control={control}
              rules={{
                required: t("form.requiredField").toString(),
                pattern: {
                  value: RegexPatterns.PASSWORD,
                  message: t("form.invalidFormat")
                }
              }}
              render={({ field, fieldState }) => (
                <PasswordField
                  label={t("UserFormDialog.password")}
                  helperText={t("UserFormDialog.passwordTootltip")}
                  customProps={{
                    placeholder: t("UserFormDialog.passwordHint"),
                    disabled: isLoadingUser,
                    required: isCreatingUser
                  }}
                  field={{ ...field }}
                  fieldState={fieldState}
                />
              )}
            />
          </Grid>
        )}
        {(!(isEditingPumatronixUser && !isPumatronixUser) ||
          isPumatronixUser) && (
          <>
            <Grid item xs={12} sm={6}>
              <Controller
                name="permissionLevel"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field }) => (
                  <SelectField
                    {...field}
                    required
                    label={t("UserFormDialog.profile")}
                    onChange={event => {
                      field.onChange(event.target.value as string);
                    }}
                    disabled={isLoadingUser}
                  >
                    <MenuItem value="">&nbsp;</MenuItem>
                    {orderBy(
                      uniqBy(permissionLevels, p => p.levelName),
                      ["levelName", ""],
                      ["asc", false]
                    ).map((permission, key) => (
                      <MenuItem key={key} value={permission.levelName}>
                        {permission.levelName}
                      </MenuItem>
                    ))}
                  </SelectField>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectField
                required
                multiple
                disabled={isLoadingUser}
                label={t("UserFormDialog.subProfile")}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                value={selectedSubLevels}
                onChange={({ target }: SelectChangeEvent<unknown>) => {
                  const val = target.value as string[];
                  setSelectedSubLevels(val);
                }}
                renderValue={param => {
                  const selected = param as string[];
                  return (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map(value => (
                        <Chip key={value} size="small" label={value} />
                      ))}
                    </Box>
                  );
                }}
              >
                {permissionSubLevels.map((permission, key) => (
                  <MenuItem key={key} value={permission}>
                    {permission}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="ignoreUserGroup"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        onChange={field.onChange}
                        disabled={isLoadingUser}
                        checked={Boolean(field.value)}
                      />
                    }
                    label={t("UserFormDialog.ignoreUserGroup").toString()}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="isAPI"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        onChange={field.onChange}
                        disabled={isLoadingUser}
                        checked={Boolean(field.value)}
                      />
                    }
                    label={t("UserFormDialog.isAPI").toString()}
                  />
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
    </FormDialog>
  );
};

export default UserFormDialog;
