import Pages from "enums/Pages";
import useMonitoringVehiclesReportAPI, {
  MonitoringVehiclesReport,
  VehiclesMake
} from "api/MonitoringVehiclesReportAPI";
import fileDownload from "js-file-download";
import { useIsMount } from "hooks/useIsMount";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { format, parseISO, set } from "date-fns";
import { Download, Sliders } from "react-feather";
import { useLocale } from "contexts/LocaleContext";
import InnerPageLayout from "layouts/InnerPageLayout";
import LogReportFilter, {
  FormFilterMonitoring
} from "pages/MonitoringReport/MonitoringReportFilter";
import DataTable from "components/DataTable";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import CaptureImgButton from "components/CaptureImgButton";
import { FC, useCallback, useEffect, useState } from "react";
import { usePageLocation } from "contexts/PageLocationContext";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { formatDateTime, setTimezone } from "utils/DateFunctions";
import CaptureImageViewerDialog, {
  CaptureImageDialog
} from "components/CaptureImageViewerDialog/CaptureImageViewerDialog";
import { parsePlateCoordinate } from "components/ImageViewer";
import { EMPTY_VALUE } from "utils/String";
import LinkPlate from "components/LinkPlate";
import PageSection from "components/PageSection/PageSection";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";
import MenuButton from "components/MenuButton";
import {
  PaginatorModel,
  defaultPaginatorModelValues
} from "components/Paginator";
import useToolsAPI from "api/ToolsAPI";

const MonitoringReportPage: FC = () => {
  const ToolsAPI = useToolsAPI();
  const defaultFilterValues: FormFilterMonitoring = {
    id: "",
    startDate: setTimezone(
      set(new Date(), { hours: 0, minutes: 0, seconds: 0 })
    ),
    endDate: setTimezone(new Date()),
    startTime: setTimezone(
      set(new Date(), { hours: 0, minutes: 0, seconds: 0 })
    ),
    endTime: setTimezone(new Date()),
    plate: "",
    description: "",
    equipment: []
  };

  const MonitoringVehiclesReportAPI = useMonitoringVehiclesReportAPI();
  const isMount = useIsMount();
  const { sessionUser } = useAuth();
  const [page, setPage] = useState(1);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reportResults, setReportResults] = useState<
    MonitoringVehiclesReport[]
  >([]);
  const [selectedVehicle, setSelectedVehicle] =
    useState<CaptureImageDialog | null>(null);
  const [makes, setMakes] = useState<VehiclesMake[]>([]);
  const [filterData, setFilterData] =
    useState<FormFilterMonitoring>(defaultFilterValues);
  const [isDownloadingCsv, setIsDownloadingCsv] = useState<boolean>(false);
  const [isDownloadingPdf, setIsDownloadingPdf] = useState<boolean>(false);
  const [paginator, setPaginator] = useState<PaginatorModel>(
    defaultPaginatorModelValues
  );
  const [pageSize, setPageSize] = useState<number>(10);
  const { t } = useTranslation();
  const { errorHandler } = useErrorHandler();
  const { language } = useLocale();

  const requestData = useCallback(
    async (
      filter: FormFilterMonitoring | undefined,
      pageValue,
      pageSizeValue: number
    ) => {
      if (!sessionUser?.["customer_id"] || !filter) return;
      const customerId = sessionUser["customer_id"];
      setIsLoading(true);
      try {
        const reportResponse = await MonitoringVehiclesReportAPI.list({
          ["customer_id"]: customerId,
          page: pageValue,
          plate: filter.plate,
          description: filter.description,
          ["final_date"]: formatDateTime(
            filter.endDate as Date,
            filter.endTime as Date
          ),
          ["initial_date"]: formatDateTime(
            filter.startDate as Date,
            filter.startTime as Date
          ),
          equipments: filter.equipment,
          ["page_size"]: pageSizeValue,
          ["vehicle_make"]: filter.make === "" ? undefined : filter.make,
          ["vehicle_model"]: filter.model === "" ? undefined : filter.model,
          ["vehicle_color"]: filter.color === "" ? undefined : filter.color
        });
        setReportResults(reportResponse.listMonitoredPlates.items || []);
        setPaginator({
          totalPages: reportResponse.listMonitoredPlates.total_pages || 0,
          totalItems: reportResponse.listMonitoredPlates.total_items || 0
        });
        setPage(pageValue);
      } catch (error) {
        errorHandler({ error });
      } finally {
        setIsLoading(false);
      }
    },
    [sessionUser]
  );

  useEffect(() => {
    if (!isMount) {
      requestData(filterData, page, pageSize);
    }
  }, [pageSize, filterData]);

  useEffect(() => {
    (async () => {
      try {
        const makeResponse = await ToolsAPI.listAllMakes();

        const liteMake = makeResponse.make
          .filter(item => item.lite === true)
          .map(item => ({ name: item.name, isDivider: false }));
        liteMake[liteMake.length - 1].isDivider = true;
        const nonLiteMake = makeResponse.make
          .filter(item => item.lite === false)
          .map(item => ({ name: item.name, isDivider: false }));
        const makes = liteMake.concat(nonLiteMake);

        setMakes(makes || []);
      } catch (error) {
        errorHandler({ error });
      }
    })();
  }, []);

  const onFilter = (data: FormFilterMonitoring) => {
    setFilterData(data);
  };

  const openDialog = (capture: MonitoringVehiclesReport) => {
    setSelectedVehicle({
      id: capture.id,
      image: capture.imageLink,
      equipment: capture.equipment,
      camera: capture.camera,
      dateTime: capture["date_capture"],
      plate: capture.plate,
      longitude: capture.longitude,
      latitude: capture.latitude,
      plateCoordinate: parsePlateCoordinate(capture.plate_coordinate)
    });
    setDialogOpen(true);
  };

  const downloadCsv = async () => {
    if (!sessionUser?.["customer_id"]) return;
    const customerId = sessionUser["customer_id"];
    if (filterData && !isDownloadingCsv) {
      try {
        setIsDownloadingCsv(true);
        const response = await MonitoringVehiclesReportAPI.downloadCsv({
          ["customer_id"]: customerId,
          plate: filterData.plate || undefined,
          description: filterData.description || undefined,
          ["final_date"]: formatDateTime(
            filterData.endDate as Date,
            filterData.endTime as Date
          ),
          ["initial_date"]: formatDateTime(
            filterData.startDate as Date,
            filterData.startTime as Date
          ),
          equipments: filterData.equipment?.length
            ? filterData.equipment
            : undefined,
          ["vehicle_make"]:
            filterData.make === "" ? undefined : filterData.make,
          ["vehicle_model"]:
            filterData.model === "" ? undefined : filterData.model,
          ["vehicle_color"]:
            filterData.color === "" ? undefined : filterData.color,
          language: language.split("-")[0]
        });
        fileDownload(response, "monitored_plates.csv", "text/csv");
      } catch (error) {
        errorHandler({ error });
      } finally {
        setIsDownloadingCsv(false);
      }
    }
  };

  const forceDownloadPdf = (pdf: string) => {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "monitored_plates.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const downloadPdf = async () => {
    if (!sessionUser?.["customer_id"]) return;
    const customerId = sessionUser["customer_id"];
    if (filterData && !isDownloadingPdf) {
      try {
        setIsDownloadingPdf(true);
        const response = await MonitoringVehiclesReportAPI.downloadPdf({
          ["customer_id"]: customerId,
          plate: filterData.plate || undefined,
          description: filterData.description || undefined,
          ["final_date"]: formatDateTime(
            filterData.endDate as Date,
            filterData.endTime as Date
          ),
          ["initial_date"]: formatDateTime(
            filterData.startDate as Date,
            filterData.startTime as Date
          ),
          equipments: filterData.equipment?.length
            ? filterData.equipment
            : undefined,
          ["vehicle_make"]:
            filterData.make === "" ? undefined : filterData.make,
          ["vehicle_model"]:
            filterData.model === "" ? undefined : filterData.model,
          ["vehicle_color"]:
            filterData.color === "" ? undefined : filterData.color,
          language: language.split("-")[0]
        });
        forceDownloadPdf(response);
      } catch (error) {
        errorHandler({ error });
      } finally {
        setIsDownloadingPdf(false);
      }
    }
  };

  const { setPageTitle, setLocation } = usePageLocation();

  useEffect(() => {
    setPageTitle(t("windowTitle.monitoringReport"));
    setLocation([
      {
        label: t("menu.reports")
      },
      {
        label: t("MonitoringReportPage.title"),
        page: Pages.MONITORING_REPORT
      }
    ]);
  }, [t, Pages]);

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <PageSection
          title={t("MonitoringReportPage.title")}
          isLoading={isLoading}
          actions={
            <MenuButton
              id="download-button"
              disabled={paginator.totalItems < 1}
              icon={<Download />}
              label={t("action.export")}
              actions={[
                {
                  key: "downloadCsv",
                  isLoading: isDownloadingCsv,
                  label: t("CaptureReportPage.exportCsv"),
                  onClick: downloadCsv
                },
                {
                  key: "downloadPdf",
                  isLoading: isDownloadingPdf,
                  label: t("CaptureReportPage.exportPdf"),
                  onClick: downloadPdf
                }
              ]}
            />
          }
          primaryActions={
            <PageSectionHeaderAction
              variant="contained"
              color="secondary"
              startIcon={<Sliders />}
              onClick={() => setIsFilterOpen(true)}
            >
              {t("action.filter")}
            </PageSectionHeaderAction>
          }
        >
          <DataTable
            watermarked
            headers={[
              { key: "id", label: t("MonitoringReportPage.id") },
              {
                key: "date_capture",
                label: t("MonitoringReportPage.datetime")
              },
              { key: "equipment", label: t("MonitoringReportPage.equipment") },
              { key: "camera", label: t("CaptureReportPage.camera") },
              { key: "plate", label: t("CaptureReportPage.plate") },
              {
                key: "description",
                label: t("MonitoringReportPage.description")
              },
              { key: "user", label: t("MonitoringReportPage.user") },
              {
                key: "picture",
                label: t("MonitoringReportPage.picture"),
                noSort: true
              }
            ]}
            defaultSort={["id", "asc"]}
            onHeaderSort={setReportResults}
            data={reportResults}
            renderRow={row => [
              <>{row.id}</>,
              <>
                {format(
                  parseISO(row.date_capture),
                  t("CaptureReportPage.dateFormat")
                )}
              </>,
              <>{row.equipment}</>,
              <>{row.camera}</>,
              <>
                <LinkPlate plate={row.plate} path={Pages.VEHICLE_OVERVIEW} />
              </>,
              <>{row.description ?? EMPTY_VALUE}</>,
              <>{EMPTY_VALUE}</>,
              <>
                <CaptureImgButton
                  imageLink={row.imageLink}
                  onClick={() => openDialog(row)}
                />
              </>
            ]}
            hideColumnsXs={[0, 3, 4, 5, 6]}
            page={page}
            onPageChange={pageValue =>
              requestData(filterData, pageValue, pageSize)
            }
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            totalPages={paginator.totalPages}
            totalItems={paginator.totalItems}
            isLoading={isLoading}
          />
        </PageSection>
      </InnerPageLayout>
      <LogReportFilter
        open={isFilterOpen}
        setOpen={setIsFilterOpen}
        onFilter={data => onFilter(data)}
        makes={makes}
      />
      <CaptureImageViewerDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        capture={selectedVehicle}
      />
    </DefaultPageLayout>
  );
};

export default MonitoringReportPage;
