import useProfileAPI, { Permission } from "api/ProfileAPI";
import Crypto from "utils/Crypto";
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from "react";
import { useAuth } from "./AuthContext";
import { Auth } from "api/AuthAPI";

type OpenItems = {
  system: boolean;
  reports: boolean;
  dashboards: boolean;
  settings: boolean;
};

const INITIAL_OPEN_ITEMS: OpenItems = {
  system: false,
  reports: false,
  settings: false,
  dashboards: false
};

type MainMenuContextValue = {
  openItems: OpenItems;
  setOpenItems: (() => void) | Dispatch<SetStateAction<OpenItems>>;
  permittedMenu: Permission[];
  defaultMenu: Permission[];
};

const MainMenuContext = createContext<MainMenuContextValue>({
  openItems: INITIAL_OPEN_ITEMS,
  setOpenItems() {
    throw new Error("setOpenItems must be defined.");
  },
  permittedMenu: [],
  defaultMenu: []
});

export const permittedMenus = (sessionUser: Auth["user"]): Permission[] => {
  if (!sessionUser) return [];
  const sessionPermissons = sessionUser?.customer_profile?.permissions;
  const storagePermissions = localStorage.getItem("customer_profile");
  const permissions = sessionPermissons
    ? sessionPermissons
    : storagePermissions;
  if (!permissions) return [];
  const menuData = JSON.parse(Crypto.decrypt(permissions));
  return [...menuData.data] as Permission[];
};

export const allMenus = (permissions: Permission[]): string[] => {
  const menus = permissions.reduce((acc, permission) => {
    permission.subMenu.forEach(subMenu => {
      acc.push(subMenu.menu);
    });
    return acc;
  }, [] as string[][]);
  return menus.flat();
};

export const MainMenuProvider: FC = ({ children }) => {
  const [openItems, setOpenItems] = useState<OpenItems>(INITIAL_OPEN_ITEMS);
  const { getDefault } = useProfileAPI();
  const [defaultMenu, setDefaultMenu] = useState<Permission[]>([]);
  const [permittedMenu, setPermittedMenu] = useState<Permission[]>([]);
  const { sessionUser } = useAuth();

  useEffect(() => {
    getDefault().then(data => {
      setDefaultMenu(data);
    });

    if (!sessionUser) return;
    setPermittedMenu(permittedMenus(sessionUser));
  }, [sessionUser]);

  return (
    <MainMenuContext.Provider
      value={{
        openItems,
        setOpenItems,
        permittedMenu,
        defaultMenu
      }}
    >
      {children}
    </MainMenuContext.Provider>
  );
};

export const useMainMenu = (): MainMenuContextValue => {
  const context = useContext(MainMenuContext);
  if (context === undefined) {
    throw new Error("useMainMenu must be used within an MainMenuProvider");
  }
  return context;
};
