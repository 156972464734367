import { AxiosResponse } from "axios";

import { User } from "api/UserAPI";
import { Customer } from "api/CustomerAPI";
import APIEndpoints from "enums/APIEndpoints";
import Crypto from "utils/Crypto";
import { Customization } from "api/CustomizationAPI";
import { Watermark } from "api/WatermarkAPI";
import useAxios, { Success } from "hooks/useAxios";

const PUMATRONIX_CUSTOMER_ID = "08823013000172";

export type Auth = {
  user: {
    status: User["status"];
    ["customer_id"]: User["customer_id"];
    ["customer_name"]: Customer["customer_name"];
    ["customization_data"]: Customization;
    ["watermark_data"]: Watermark;
    ["level_name"]?: User["level_name"];
    ["sub_level_name"]?: User["sub_level_name"];
    ["user_data"]: User["user_data"];
    username: User["username"];
    name: User["name"];
    isPumatronixUser: boolean;
    isTermsAccepted?: boolean;
    apiAuthCode: string;
    ["customer_profile"]?: {
      ["profile"]: string;
      ["permissions"]?: string;
    };
  };
  ["access_token"]: string;
  ["refresh_token"]: string;
};

type SignInParams = {
  username: string;
  password: string;
};
type SignOutParams = {
  username: string;
  accessToken: string;
};

type ConfirmPasswordResetParams = {
  code: string;
  username: string;
  password: string;
};

type ConfirmUserParams = {
  username: string;
  code: string;
};

type ConfirmForgotPasswordParams = {
  username: string;
  code: string;
};

type SendPasswordResetCodeParams = { username: string };

type UseAuthAPIType = {
  signIn: (param: SignInParams) => Promise<Auth>;
  signOut: (param: SignOutParams) => Promise<Success>;
  sendPasswordResetCode: (
    param: SendPasswordResetCodeParams
  ) => Promise<Success>;
  resendAccessCode: (param: SendPasswordResetCodeParams) => Promise<Success>;
  confirmPasswordReset: (param: ConfirmPasswordResetParams) => Promise<Success>;
  confirmUser: (param: ConfirmUserParams) => Promise<Success>;
  confirmForgotPassword: (
    param: ConfirmForgotPasswordParams
  ) => Promise<Success>;
};

const useAuthAPI = (): UseAuthAPIType => {
  const API = useAxios();

  const signIn = async ({
    username,
    password
  }: SignInParams): Promise<Auth> => {
    const encryptedPassword = Crypto.encrypt(password);
    const response: AxiosResponse<Auth> = await API.instance.post(
      APIEndpoints.LOGIN,
      {
        username,
        password: encryptedPassword
      }
    );
    return {
      ...response.data,
      user: {
        ...response.data.user,
        apiAuthCode: encryptedPassword,
        isPumatronixUser:
          response.data.user["customer_id"] === PUMATRONIX_CUSTOMER_ID,
        isTermsAccepted: response.data.user.user_data?.accept_licence ?? false
      }
    };
  };

  const signOut = async ({
    username,
    accessToken
  }: SignOutParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.LOGOUT,
      {
        username,
        ["access_token"]: accessToken
      },
      {
        headers: {
          ["authorizationToken"]: accessToken
        }
      }
    );
    return response.data;
  };

  const sendPasswordResetCode = async ({
    username
  }: SendPasswordResetCodeParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.FORGOT_PASSWORD,
      { username }
    );
    return response.data;
  };

  const resendAccessCode = async ({
    username
  }: SendPasswordResetCodeParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.RESEND_ACCESS_CODE,
      { username }
    );
    return response.data;
  };

  const confirmPasswordReset = async ({
    code,
    username,
    password
  }: ConfirmPasswordResetParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.CONFIRM_FORGOT_PASSWORD,
      {
        code,
        username,
        password: Crypto.encrypt(password)
      }
    );
    return response.data;
  };

  const confirmUser = async ({
    username,
    code
  }: ConfirmUserParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.CONFIRM_USER,
      { username, code }
    );
    return response.data;
  };

  const confirmForgotPassword = async ({
    username,
    code
  }: ConfirmForgotPasswordParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.CONFIRM_FORGOT_PASSWORD,
      { username, code }
    );
    return response.data;
  };

  return {
    signIn,
    signOut,
    confirmUser,
    resendAccessCode,
    sendPasswordResetCode,
    confirmPasswordReset,
    confirmForgotPassword
  };
};

export default useAuthAPI;
