import { utcToZonedTime } from "date-fns-tz";
import { SchedulerDate } from "api/MonitoringAPI";
import { format, formatISO, parseISO, toDate } from "date-fns";

export const fromDateToUtc = (date: Date): string => format(date, "dd/MM/yyyy");
export const fromDateToUtc2 = (date: Date): string =>
  format(date, "yyyy-MM-dd");

export const fomatDateToTestCase = (date: Date): string =>
  format(date, "MM/dd/yyyy");

export const fomatTimeToTestCase = (date: Date): string =>
  format(date, "HH:mm");

export const fomatHour = (date: Date): string => format(date, "HH:mm:ss");

export const fromUtcToDate = (date: string | undefined): Date | null => {
  if (!date) return null;
  const split = date.split("/");
  return toDate(parseISO(`${split[2]}-${split[1]}-${split[0]}`));
};

export const formatDateTime = (date: Date, time: Date): string => {
  const dateTime = date;
  dateTime.setHours(time.getHours());
  dateTime.setMinutes(time.getMinutes());
  return formatISO(dateTime);
};

export const msToSeconds = (ms: string): string =>
  (parseFloat(ms) / 1000).toFixed(3);

export const formatSchedulerDateTime = (
  schedulerDates: SchedulerDate[]
): SchedulerDate[] => {
  const newData = schedulerDates;

  newData.forEach((scheduler, indexS) => {
    scheduler.hours_intervals.forEach((hour, indexH) => {
      const timezoneHour = {
        ["start_hour"]:
          typeof hour.start_hour === "string"
            ? hour.start_hour
            : format(hour.start_hour as Date, "HH:mm"),
        ["end_hour"]:
          typeof hour.end_hour === "string"
            ? hour.end_hour
            : format(hour.end_hour as Date, "HH:mm")
      };
      if (newData) newData[indexS].hours_intervals[indexH] = timezoneHour;
    });
  });

  return newData;
};

export const setTimezone = (date: Date): Date => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return utcToZonedTime(date, timezone);
};
